.navegador-container {
  padding-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navegador-container h2 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 18px;
}

.navegador-container button {
  max-width: 450px;
}

.navegador-container button.copiar-link {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}
