.passos-progresso {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
}

.passos-progresso span {
  height: 4px;
  width: 100%;
  border: 1px solid #ccc;
  margin-right: 2px;
}

.passos-progresso span:last-child {
  margin: 0;
}

.passos-progresso span.highlight {
  background: linear-gradient(162.43deg, #3249af 49.02%, #507cea 107.26%);
}

@media screen and (max-width: 800px) {
  .passos-progresso {
    margin-top: 6vh;
    position: fixed;
  }
}
