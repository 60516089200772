.autenticacao-container section.form {
  width: 100%;
  max-width: 450px;
}

.autenticacao-container h4,
.autenticacao-container h5 {
  margin-top: 25px;
}

.autenticacao-container section.form h1,
.autenticacao-container section.form h2 {
  color: rgb(20, 50, 75);
}

.modal-container button.recomecar-assinatura {
  border: 1px solid rgb(20, 50, 75);
  background-color: white;
  color: rgb(20, 50, 75);
}

.modal-container h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-container #volume-aviso {
  width: 200px;
}
