.orientacao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.orientacao-container button {
  max-width: 450px;
  margin-top: 40px;
}

@media screen and (max-width: 414px) {
  .orientacao-container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .orientacao-container {
    padding-top: 50px;
  }
}
