.template-container {
  width: 100%;
  /* max-width: 80%; */
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.template-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 15px;
}
.template-instrucoes {
  background-color: #e15100;
  padding: 40px 4px 20px 4px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.div-documento {
  border: 0.2px solid #55555555;
  padding: 10px;
  margin: 20px;
  box-shadow: 2px 5px 4px 5px #33333333;
}
.div-documento p {
  padding: 10px;
  font-size: 15px;
  text-align: justify;
}
.text-align-right {
  text-align: right !important;
}

@media screen and (max-width: 414px) {
  .template-container {
    max-width: 100%;
  }
}