.identificacao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.identificacao-container .titulo {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.identificacao-container .camera {
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.identificacao-container .camera.loader-camera {
  margin: 85px;
  width: auto;
}

.identificacao-container .camera img,
.identificacao-container .camera video {
  border-radius: 5px;
  max-width: 450px;
}

.identificacao-container .camera img {
  border: 1px solid #5889fa;
}

.identificacao-container .acoes {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.identificacao-container .acoes button {
  padding: 0;
  line-height: unset;

  height: 40px;
  width: 250px;
}

.identificacao-container .frente-verso {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.identificacao-container .frente-verso img {
  width: 200px;
}

@media screen and (max-width: 800px) {
  .identificacao-container {
    padding: 0 16px;
    padding-top: 65px;
  }

  .identificacao-container > div {
    width: 100%;
  }

  .identificacao-container .frente-verso {
    flex-direction: column;
  }

  .identificacao-container .acoes button {
    font-size: 15px;
  }

  .identificacao-container .camera.loader-camera {
    margin: 25px;
  }

  .identificacao-container .camera {
    /* width: 100%; */
    height: 220px;
    object-fit: cover;
  }

  .identificacao-container .camera img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .identificacao-container .camera {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .identificacao-container .camera {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .identificacao-container .camera img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
}
