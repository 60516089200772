.container-termo-adesao {
  /* padding-top: 50px; */
}

.container-termo-adesao p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 10px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

.container-termo-adesao .div-condicoes > p {
  font-size: 15px !important;
  padding: 0px !important;
  text-align: center;
}

.container-termo-adesao .lista-condicoes {
  padding: 0 5px;
  margin-top: 20px;
}

.container-termo-adesao ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.container-termo-adesao ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.container-termo-adesao ol > li > span {
  font-weight: 700;
  margin-bottom: 10px;
}

.container-termo-adesao ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.container-termo-adesao .container-termo-adesao li ol > li {
  margin: 0;
}

.container-termo-adesao li ol > li:before {
  content: counters(item, ".") " ";
}

.container-termo-adesao table tbody tr td input[type="checkbox"] {
  margin-right: 10px;
  width: 10px;
}

.container-termo-adesao table .info-conta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 8px;
}

.container-termo-adesao table .info-conta thead tr th {
  border-radius: unset;
}

.container-termo-adesao table .info-conta thead tr th:first-child {
  border-top-left-radius: 10px;
}

.container-termo-adesao table .info-conta thead tr th:last-child {
  border-top-right-radius: 10px;
}

.container-termo-adesao table .info-conta tbody tr td {
  width: auto;
  border: none;
}

.container-termo-adesao table .info-conta .titulo {
  font-weight: 700;
}
