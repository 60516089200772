.resumo-fgts-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.resumo-fgts-container h1 {
  margin-bottom: 30px;
  max-width: 260px;
}

.resumo-fgts-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 30px;
  font-weight: 700;
}

.resumo-fgts-container .resumo-valor-fgts {
  margin-bottom: 20px;
  width: 100%;
}

.resumo-fgts-container .resumo-valor-fgts label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  margin-left: 0;
}

.resumo-fgts-container .resumo-valor-fgts div {
  background-color: #e15100;
  padding: 13px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-fgts-container .resumo-valor-fgts span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-fgts-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 100%;
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
}

.resumo-fgts-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 50%;
}

.resumo-fgts-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
  margin: 0;
}

.resumo-fgts-container .resumo-informacoes-adicionais span {
  color: #2b3ea1;
  font-weight: 700;
  font-size: 15px;
}

.resumo-fgts-container button {
  max-width: 450px;
}

.resumo-fgts-container .tabela-antecipacoes{
  width: 100%;
  text-align: center;
}

.resumo-fgts-container .tabela-antecipacoes label {
  font-weight: 700;
}

.resumo-fgts-container .tabela-antecipacoes th {
  padding: 5px;
  text-align: center;
}

.resumo-fgts-container .tabela-antecipacoes table {
  margin-bottom: 0;
}

.resumo-fgts-container .tabela-antecipacoes td {
  width: 33.33%;
  padding: 6px 2px;
}

@media screen and (max-width: 800px) {
  .resumo-fgts-container{
    padding-top: 20px;
  }
}
