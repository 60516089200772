.container-termo-adesao-fgts {
  /* padding-top: 50px; */
}

.container-termo-adesao-fgts table tbody tr td input[type=checkbox] {
  margin-right: 10px;
  width: 10px;
}

.container-termo-adesao-fgts table .info-conta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 8px;
}

.container-termo-adesao-fgts table .info-conta thead tr th {
  border-radius: unset;
}

.container-termo-adesao-fgts table .info-conta thead tr th:first-child {
  border-top-left-radius: 10px;
}

.container-termo-adesao-fgts table .info-conta thead tr th:last-child {
  border-top-right-radius: 10px;
}

.container-termo-adesao-fgts table .info-conta tbody tr td {
  width: auto;
  border: none;
}

.container-termo-adesao-fgts table .info-conta .titulo {
  font-weight: 700;
}

.container-termo-adesao-fgts .antecipacoes span {
  display: block;
}

.container-termo-adesao-fgts .antecipacao-small {
  display: table-row;
}

.container-termo-adesao-fgts .lista-condicoes {
  padding: 0 5px;
  margin-top: 20px;
}

.container-termo-adesao-fgts ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.container-termo-adesao-fgts ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.container-termo-adesao-fgts ol > li > span {
  font-weight: 700;
  margin-bottom: 10px;
}

.container-termo-adesao-fgts ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.container-termo-adesao-fgts .container-termo-adesao li ol > li {
  margin: 0;
}

.container-termo-adesao-fgts li ol > li:before {
  content: counters(item, ".") " ";
}

@media screen and (max-width: 465px) {
  .container-termo-adesao-fgts .antecipacao-small {
    display: table-row;
  }

  .container-termo-adesao-fgts .antecipacao-small td {
    width: 100%;
  }

  .container-termo-adesao-fgts .antecipacao-large {
    display: none;
  }
}
