.header {
  align-content: center;
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.header img.logout-portal {
  margin-right: 15px;
}

.header img.logo {
  position: absolute;
  width: 18%;
}

.header img:last-child {
  margin-left: auto;
  cursor: pointer;
}

.header img.audio {
  width: 20px;
  position: absolute;
  right: 5px;
}

.header #logo-b {
  width: 100%;
  height: auto;
  max-width: 35px;
  position: absolute;
  left: 5px;
}

.header #logo-b.fundacao {
  max-width: 160px;
}

.header nav.menu-assinatura ul {
  display: flex;
}

.header nav.menu-assinatura ul li {
  list-style: none;
}

.header nav.menu-assinatura ul li a {
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0.4rem 2.4rem;
  transition: all 250ms linear 0s;
}

.header nav.menu-assinatura ul li a:hover {
  background: rgba(80, 78, 78, 0.15);
}

.menu-section nav.menu-assinatura ul a.menu-highlight {
  background: rgba(80, 78, 78, 0.15);
}

.container-header-progresso {
  display: flex;
  flex-direction: column;
}

@media (max-width: 540px) {
  .header {
    justify-content: space-between;
  }

  .header #logo-b {
    position: relative;
  }

  nav.menu-assinatura {
    display: none;
  }

  .one,
  .two,
  .three {
    background-color: #a19595;
    height: 1px;
    width: 100%;
    margin: 6px auto;

    transition-duration: 0.3s;
  }

  .menu-toggle {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
  }

  .menu-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-section.on {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #e15100;

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav.menu-assinatura {
    display: block;
  }

  .menu-section.on .menu-toggle {
    position: absolute;
    right: 5px;
    top: 10px;
  }

  .menu-section.on .menu-toggle .one,
  .menu-section.on .menu-toggle .two,
  .menu-section.on .menu-toggle .three {
    background-color: #fff;
  }

  .menu-section.on .menu-toggle .one {
    transform: rotate(45deg) translate(4px, 5px);
  }

  .menu-section.on .menu-toggle .two {
    opacity: 0;
  }

  .menu-section.on .menu-toggle .three {
    transform: rotate(-45deg) translate(4px, -7px);
  }

  .menu-section.on nav.menu-assinatura ul {
    text-align: center;
    display: block;
  }

  .menu-section.on nav.menu-assinatura ul a {
    color: #fff;
    transition-duration: 0.5s;
    font-size: 0.8rem;
    line-height: 4rem;
    display: block;
    margin: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .header #logo-b {
    max-width: 30px;
    display: block;
  }

  .header img.logo {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .header img.logo {
    width: 50%;
  }

  .header #logo-b {
    max-width: 28px;
  }

  .header #logo-b.fundacao {
    max-width: 130px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .header img.logo {
    width: 60%;
  }

  .header #logo-b {
    max-width: 25px;
  }

  .header #logo-b.fundacao {
    max-width: 100px;
  }
}

.header-bemsign {
  background: linear-gradient(73.16deg, #ffbe2d 11.41%, #ff6518 74.48%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.header-bemsign img {
  width: 100%;
  max-height: 80px;
}
