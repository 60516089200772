.relatorio-container {
    padding: 0 20px;
    padding-top: 50px;
    margin-bottom: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.relatorio-container button.button-bem {
    background-color: #010050
}

.relatorio-container .busca {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.relatorio-container .busca form {
    display: flex;
    align-items: center;
}

.relatorio-container .busca fieldset {
    margin-right: 10px;
    margin-bottom: 5px;
}

.relatorio-container .busca form input {
    height: 35px;
}

.relatorio-container .busca .campos {
    display: flex;
}

.relatorio-container .busca button {
    height: auto;
    width: 150px;
    line-height: 35px;
    margin-top: 5px;
}

.relatorio-container button {
    max-width: 450px;
}

@media screen and (max-width: 800px) {
    .relatorio-container .busca form {
        flex-direction: column;
    }

    .relatorio-container .busca form .campos {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .relatorio-container .busca form .campos fieldset {
        margin-right: 0;
    }

    .relatorio-container .busca form .botao {
        width: 100%;

        display: flex;
    }
}