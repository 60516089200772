.video-usuario-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video-usuario-container,
.score-biometrico-container {
  width: 100%;
}

.video-usuario-container h1 {
  color: rgb(20, 50, 75);
}

.video-usuario-container video {
  width: 100%;
  max-width: 450px;
}

.video-usuario-container button {
  max-width: 450px;
}

.spinner-loader {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.score-biometrico-container .table-small td {
  font-size: 12px;
  padding: 2px 6px;
}

.score-biometrico-container .table-group tbody tr td {
  width: 0;
}

.score-biometrico-container .table-group tbody tr td,
.score-biometrico-container .table-group thead tr th {
  text-align: center;
}

.score-biometrico-container .table-group tbody tr .td-image{
  border: solid 1px #e15100;
  border-bottom: 0;
  border-right: 0;
  width: 22%;
}

.width33 {
  width: 33.33% !important;
}