.biometria-score-container {
    padding: 0 20px;
    padding-top: 50px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.biometria-score-container button.button-bem {
    background-color: #010050;
    max-width: 450px;
}

.biometria-score-container button.finalizar {
    border: 1px solid #010050;
    background-color: #fff;
    color: #010050;
}

.biometria-score-container .informacoes {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.biometria-score-container form {
    width: 100%;
    max-width: 450px;
}

.biometria-score-container .camera {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.biometria-score-container .camera video {
    clip-path: ellipse(90px 140px at 50% 50%);

    width: 100%;
    height: 100%;
}