.confirmacao-dados-container .acoes a {
  margin-right: 30px;
  color: #2b3ea1;
  font-size: 16px;
  cursor: pointer;
}

.confirmacao-dados-container form {
  width: 100%;
}

.confirmacao-dados-container form .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.confirmacao-dados-container form .checkbox-group .checkbox-concordo {
  width: 15px;
  height: 15px;
}

.confirmacao-dados-container form .checkbox-group .checkbox-label {
  font-size: 10px;
}

.acoes-modal-confirmacao {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
}

.acoes-modal-confirmacao a {
  margin-right: 25px;
  color: #2b3ea1;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 455px) {
  .acoes-modal-confirmacao {
    margin-top: 15px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  .acoes-modal-confirmacao .button-bem-v2 {
    margin-top: 10px;
  }
}
