.loading-container {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 65px); */
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 60px;
}

.overlay-container {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.52);
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
