@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Inter, sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  color: #212121;
}

input {
  font: 500 18px Inter, sans-serif;
}

button,
textarea {
  font: 400 18px Inter, sans-serif;
}

button {
  cursor: pointer;
}

form input,
input[type="tel"] {
  width: 100%;
  height: 48px;
  color: #212121;
  border: none;
  border-radius: 8px;
  padding: 0px 0px 5px 10px;
  margin: 0;
}

.container h1 {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.container h2 {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.container h3 {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  text-align: center;
}

.button-bem {
  width: 100%;
  height: 64px;
  background: #2b3ea1;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: filter 0.2s;
}

.button-bem-v2 {
  height: 48px;
  background: #2b3ea1;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  margin-top: 35px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: filter 0.2s;
  padding: 0 60px;
}

.button-bem-v2.secundario {
  border: 1px solid #2b3ea1;
  background-color: white;
  color: #2b3ea1;
}

.button-bem:hover {
  filter: brightness(90%);
}

.button-bem:disabled {
  background: #eeeeee;
  color: #9e9e9e;
}

.button-bem-v2:hover {
  filter: brightness(90%);
}

.button-bem-v2:disabled:hover {
  filter: unset;
}

.button-bem-v2:disabled {
  background: #eeeeee !important;
  color: #9e9e9e !important;
}

.enabled {
  display: block;
}

.hidden {
  display: none;
}

.input-erro {
  color: red;
}

.input-erro legend {
  color: red;
}

.container {
  padding: 10px;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1120px) {
  body {
    background-color: #ebebeb;
  }
  /* h2 {
    margin-bottom: 45px;
  } */
  .container {
    border: solid 1px #cccccc;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .button-bem-v2 {
    font-size: 12px;
  }
}

@media screen and (min-width: 800px) {
  .container {
    align-items: center;
    /* min-height: 92vh; */
  }
}

@media screen and (max-width: 800px) {
  .header {
    background-color: #ffffff;
    position: fixed;
    z-index: 999;
  }

  .button-bem-v2:last-child {
    margin-top: 10px;
  }

  .button-bem-v2 {
    margin-top: 0;
    width: 100%;
  }

  /* .container {
    min-height: 92vh;
  } */
}

@media screen and (max-width: 500px) {
  .button-bem-v2 {
    width: 100%;
  }
}

@media screen and (max-height: 680px) {
  /* h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 16px;
  } */

  /* .button-bem {
    background-color: green;
  } */
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .button-bem {
    font-size: 14px;
  }
}

.table-group {
  border-left: solid 1px #e15100;
  border-right: solid 1px #e15100;
  border-bottom: solid 1px #e15100;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 1120px;
}

.table-group.retratil {
  border: 0;
}

.table-group.retratil thead tr th {
  border-radius: 10px;
}

.table-group thead tr th {
  font-size: 14px;
  font-weight: normal;
  padding: 12px;
  text-align: left;
  background-color: #e15100;
  color: white;
}

.table-group thead tr th:first-child {
  border-top-left-radius: 10px;
}

.table-group thead tr th:last-child {
  border-top-right-radius: 10px;
}

.table-group tbody tr td {
  font-weight: 400;
  font-size: 16px;
  padding: 12px;
  border-top: solid 1px #e15100;
  width: 50%;
}

/*
TABLE V2
*/

.table-group-v2 {
  border-left: solid 1px #dee0e1;
  border-right: solid 1px #dee0e1;
  border-bottom: solid 1px #dee0e1;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.table-group-v2.retratil {
  border: 0;
}

.table-group-v2.retratil thead tr th {
  border-radius: 10px;
}

.table-group-v2 thead tr th {
  font-size: 14px;
  font-weight: normal;
  padding: 12px;
  text-align: left;
  background-color: #2b3ea1;
  color: white;
}

.table-group-v2 thead tr th:first-child {
  border-top-left-radius: 10px;
}

.table-group-v2 thead tr th:last-child {
  border-top-right-radius: 10px;
}

.table-group-v2 tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.table-group-v2 tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table-group-v2.arredonda-ultimo tbody tr:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-group-v2 tbody tr td {
  font-weight: 400;
  font-size: 16px;
  padding: 12px;
  border-top: solid 1px #dee0e1;
  background-color: #fff;
}

/*
TABLE V2
*/

.center {
  text-align: center;
}

fieldset {
  padding: 0px;
  border-radius: 5px;
  border: solid 1px;
  margin-bottom: 20px;
}

fieldset legend {
  margin-left: 10px;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbbb7;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}
