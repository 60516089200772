.localizacao-container h4,
.localizacao-container h5 {
  margin-top: 25px;
}

@media screen and (max-width: 800px) {
  .localizacao-container img {
    width: 100%;
  }
}
