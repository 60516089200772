.validador-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  min-height: 100vh;
}

.validador-container .bemsign {
  width: 100%;
  max-width: 28%;
  height: auto;
  background: linear-gradient(73.16deg, #ffbe2d 11.41%, #ff6518 74.48%);
  padding: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.validador-container .bemsign .info {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
}

.validador-container .bemsign .info img#logo-bemsign {
  width: 200px;
}

.validador-container .bemsign .info p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #fff;
  margin-top: 5px;
}

.validador-container .conteudo {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 15px 0px 15px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.validador-container .conteudo header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.validador-container .conteudo header svg {
  position: absolute;
  left: 0;
}

.validador-container h1 {
  color: #414141;
  display: flex;
  align-items: center;
  justify-content: center;
}

.validador-container h1.titulo b {
  color: #e15100;
  margin-left: 5px;
}

.validador-container .dados-assinatura {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.validador-container .dados-assinatura .container-dados {
  padding: 10px;
}

.validador-container .dados-assinatura .container-dados.foto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dados-assinatura .container-dados .foto-cliente {
  height: 250px;
  border: 2px solid #ccc;
}

.validador-container .dados-assinatura div {
  width: 100%;
}

.validador-container .dados-assinatura div label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: #a7a7a7;
}

.validador-container .dados-assinatura div p {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  padding: 5px 0;
  margin-bottom: 10px;
  color: #545454;
  word-wrap: break-word;
}

.validador-container .dados-assinatura div p.validado {
  color: green;
}

.validador-container input.identificador-documento {
  width: 80%;
  font-size: 1em;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #333;
}

.validador-container button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.validador-container button.secundario {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.validador-container button,
.validador-container .video-qrcode div video {
  max-width: 450px;
}

.validador-container button {
  font-size: 0.89em;
}

.validador-container button svg {
  margin-right: 10px;
}

.validador-container button.button-fundo-vazado,
.validador-container button.button-fundo-escuro {
  height: auto;
  line-height: 50px;
  margin-top: 10px;
}

.validador-container button.button-fundo-escuro {
  background-color: #e15100;
}

.validador-container button.button-fundo-vazado {
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  color: #e15100;
}

.validador-container .video-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.validador-container .video-qrcode div {
  padding: 0 !important;
}

.validador-container .video-qrcode div video {
  border: 5px solid #2b3ea1;
  border-radius: 8px;
  position: inherit !important;
}

.validador-container .dica-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .instrucao {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .instrucao p {
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 20px;
  color: #787875;
}

.validador-container .dica-qrcode .instrucao p:last-child {
  margin-bottom: 30px;
}

.validador-container .dica-qrcode .exemplo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .exemplo img {
  min-width: 239px;
  margin-bottom: 10px;
}

.validador-container .container-verificacao-assinatura {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 15px;
  margin-bottom: 100px;
  gap: 20px;
}

.validador-container .dados-assinatura img {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1065px) {
  .validador-container {
    justify-content: center;
    height: auto;
  }

  .validador-container .bemsign {
    display: none;
  }

  .validador-container .botoes-download button {
    width: 350px;
  }
}

@media screen and (max-width: 950px) {
  .validador-container .container-verificacao-assinatura {
    padding: 10px 10px;
  }

  .validador-container .dados-assinatura {
    flex-direction: column;
    padding: 10px;
  }

  .validador-container .dados-assinatura div:first-child {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 750px) {
  .validador-container .dica-qrcode .instrucao p {
    font-size: 15px;
  }

  .validador-container h1 {
    font-size: 20px;
  }

  .validador-container .botoes-download button {
    width: 230px;
  }

  .validador-container .dados-assinatura div p {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .validador-container button.button-resize,
  .validador-container h1.titulo {
    width: 95%;
  }

  .validador-container .botoes-download button {
    width: 220px;
  }

  .validador-container .botoes-download {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
