.resumo-ccb-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.resumo-ccb-container h1 {
  margin-bottom: 40px;
  max-width: 260px;
}

.resumo-ccb-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 40px;
}

.resumo-ccb-container .resumo-valor-ccb {
  margin-bottom: 30px;
}

.resumo-ccb-container .resumo-valor-ccb label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
}

.resumo-ccb-container .resumo-valor-ccb div {
  background-color: #e15100;
  padding: 15px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-ccb-container .resumo-valor-ccb span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-ccb-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 220px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
}

.resumo-ccb-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 50%;
}

.resumo-ccb-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
}

.resumo-ccb-container .resumo-informacoes-adicionais span {
  color: #e15100;
  font-weight: 700;
  font-size: 15px;
}

.resumo-ccb-container button {
  max-width: 450px;
}

@media screen and (max-width: 414px) {
  .resumo-ccb-container .resumo-valor-ccb div {
    width: auto;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .resumo-ccb-container {
    max-width: 160px;
  }

  .resumo-ccb-container .resumo-valor-ccb div {
    max-width: 160px;
    min-width: 160px;
  }

  .resumo-ccb-container .resumo-informacoes-adicionais {
    min-width: 160px;
    max-width: 160px;
  }

  .resumo-ccb-container .resumo-valor-ccb span {
    font-size: 20px;
  }

  .resumo-ccb-container h1 {
    max-width: 160px;
  }
}
