.token-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.token-container .titulo-token {
  margin-bottom: 20px;
}

.token-container h5 {
  margin-bottom: 15px;
}

.token-container section.form {
  width: 100%;
  max-width: 450px;
}

.token-container section.form h1 {
  font-size: 23px;
}

.token-container section.form h2 {
  margin-bottom: 15px;
}

.token-container section.form input {
  border: 1px solid rgb(139, 139, 139);
  height: 50px;
  margin-bottom: 15px;
  font-size: 15px;
}

.token-container section.form p {
  font-weight: 600;
  font-size: 14px;
}

.token-container section.form a {
  cursor: pointer;
  color: #0B45E4;
}

.token-container section.form img {
  margin: 0 auto;
  width: 60px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}
