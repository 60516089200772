.localizacao-aviso-container {
  background-color: #e15100;
  color: white;

  flex-direction: column;
}

.localizacao-aviso-container img {
    margin-bottom: 25px;
}

.localizacao-aviso-container button {
    max-width: 450px;
}

.localizacao-aviso-container button.finalizar {
    border: 1px solid white;
    background-color: #e15100;
}
