.container-aumento-portabilidade p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

@media screen and (max-height: 680px) {
  .container-aumento-portabilidade {
    /* padding-top: 50px; */
  }
}
