.aviso-container {
  background: #fff;

  flex-direction: column;
}

.aviso-container svg {
  margin-bottom: 25px;
}

.aviso-container h3 {
  margin-bottom: 15px;
}

.aviso-container .lista-alertas {
  margin-top: 15px;
}

.aviso-container .lista-alertas li {
  color: #616161;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 20px !important;
  }
}
