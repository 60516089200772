.camera-instrucao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.camera-instrucao-container button {
  margin-top: 20px;
  max-width: 450px;
}

.camera-instrucao-container .instrucao-camera {
  font-size: 20px;
}

.camera-instrucao-container .instrucao-camera h1 {
  margin-bottom: 10px;
}

.camera-instrucao-container .instrucao-camera ol {
  margin-left: 2em;
}

@media screen and (max-width: 414px) {
  .camera-instrucao-container {
    padding-top: 50px;
  }
  .camera-instrucao-container .instrucao-camera {
    font-size: 1.5em;
  }
  .camera-instrucao-container .instrucao-camera ol {
    margin-left: 2em;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .camera-instrucao-container {
    padding-top: 50px;
  }
  .camera-instrucao-container .instrucao-camera {
    font-size: 1em;
  }
  .camera-instrucao-container .instrucao-camera ol {
    margin-left: 2em;
  }
}
