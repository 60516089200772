.informacoes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.informacoes-container button {
  max-width: 450px;
}

.informacoes-container .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.informacoes-container .checkbox-concordo {
  width: 28px;
  height: 28px;
}

.informacoes-container label {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.informacoes-container table thead tr th {
  border-radius: unset;
}

.informacoes-container table thead tr th:first-child {
  border-top-left-radius: 10px;
}

.informacoes-container table thead tr th:last-child {
  border-top-right-radius: 10px;
}

.informacoes-container table tbody tr td {
  width: auto;
}

@media screen and (max-width: 414px) {
  .informacoes-container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .informacoes-container {
    padding-top: 50px;
  }
}
