.anexo-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 150px;
  width: 100%;
}

.anexo-container h4 {
  margin-bottom: 25px;
}

.anexo-container button.button-bem-v2 {
  max-width: 450px;
}

.anexo-container .acoes-anexo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.anexo-container button.button-branco {
  border: 1px solid #2b3ea1;
  background-color: white;
  color: #2b3ea1;
}

.anexo-container ul li {
  color: #2b3ea1;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .anexo-container {
    padding-top: 50px;
  }

  .anexo-container button.button-bem {
    font-size: 15px;
  }
}
