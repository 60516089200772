.webcam {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.webcam h1 {
  font-size: 20px;
  height: 50px;
}

.webcam h2 {
  font-size: 14px;
}

.webcam button,
.previsao-buttons {
  width: 100%;
  max-width: 450px;
}

.webcam .previsao-buttons button.tirar-novamente {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.webcam .camera-foto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.webcam .camera-foto img.previsao-foto {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.webcam .camera-foto .group-template-camera {
  max-width: 450px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  position: relative;
}

.webcam .camera-foto .group-template-camera video {
  clip-path: ellipse(90px 140px at 50% 50%);

  width: 100%;
  height: 100%;
}

.webcam .mensagem-instrucao {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(20, 50, 75);
  padding: 15px;
  width: 100%;
}

.webcam .mensagem-instrucao span {
  color: #fff;
  font-weight: 700;
}

.webcam .logo-empresa img {
  max-width: 35%;
  margin-top: 15px;
}

.logo-fundacao {
  min-width: 300px;
  max-height: 300px;
}

/************************************************************************
************************************************************************
************************************************************************
************************************************************************/

@keyframes animacao {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 0.9;
  }
}

@-webkit-keyframes animacao {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 0.9;
  }
}

.indicador-instrucao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
}

.seta {
  border-color: transparent;
  border-style: solid;
  border-width: 0 2em;
  display: block;
  height: 0;
  /* margin:10em auto; */
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;
}

.animacao {
  animation: animacao 0.6s infinite alternate ease-in-out;
  -webkit-animation: animacao 0.6s infinite alternate ease-in-out;
  border-bottom: 2em solid red;
}

.seta-direita {
  align-items: center;
  justify-content: flex-end !important;
}

.seta-esquerda {
  align-items: center;
  justify-content: flex-start !important;
}

.seta-cima {
  align-items: flex-start;
  justify-content: center !important;
}

.seta-baixo {
  align-items: flex-end;
  justify-content: center !important;
}

/************************************************************************
************************************************************************
************************************************************************
************************************************************************/

@media screen and (max-width: 414px) {
  .webcam .camera-foto {
    max-height: 300px;
  }

  .webcam .camera-foto video {
    height: 150px;
  }

  .webcam .camera-foto img.template-face {
    width: 140px;
  }

  .webcam h1 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .webcam h2 {
    font-size: 1em;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .webcam .camera-foto video {
    height: 140px;
  }

  .webcam .camera-foto img.template-face {
    width: 130px;
  }
}
