.tentativa-container {
    background: linear-gradient(162.43deg, #e15100 49.02%, #ff9e25 107.26%);
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tentativa-container img {
    margin-bottom: 20px;
}