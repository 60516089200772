.container-imagem-anexada {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagem-anexada {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.button-anexo-bem {
  width: 100%;
  background-color: #fafafa;
  border: 1px dashed #dee0e1;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.button-anexo-bem p.titulo {
  font-size: 16px;
  color: #212121;
  margin-bottom: 5px;
}

.button-anexo-bem p.subtitulo {
  font-size: 12px;
  color: #616161;
}

@media screen and (max-width: 414px) {
  /* .anexo-container {
    padding-top: 50px;
  } */
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  /* .anexo-container {
    padding-top: 50px;
  } */
}
