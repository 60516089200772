.subrogacao p {
  line-height: 22px;
  padding: 0 10px 15px;
  text-align: justify;
}

.subrogacao p.n2 {
  padding: 0 10px 10px 25px;
  margin-top: -15px;
}

.subrogacao table thead th {
  font-size: 13px;
  font-weight: bold;
}

.subrogacao table tbody td {
  font-size: 12px;
}

.subrogacao .table-container {
  width: 100%;
  margin-bottom: 15px;
}

.subrogacao .table-container p {
  padding-bottom: 0;
}

@media screen and (max-width: 800px) {
  body {
      font-size: 13px;
  }

  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 50px;
      border-bottom: 0;
  }
}

@media screen and (max-width: 431px) {
  .table-group tbody tr td,
  .table-group-v2 tbody tr td {
      font-size: 11px;
  }
}

@media screen and (max-width: 411px) {
  .table-group tbody tr td:nth-child(1),
  .table-group-v2 tbody tr td:nth-child(1)  {
      min-height: 46px;
  }
}

@media screen and (max-width: 399px) {
  .table-group tbody tr td:nth-child(3),
  .table-group-v2 tbody tr td:nth-child(3) {
      min-height: 46px;
  }
}

@media screen and (max-width: 335px) {
  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 68px;
  }
}

@media screen and (max-width: 276px) {
  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 95px;
  }
}