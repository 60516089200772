.login-container {
    padding: 0 20px;
    padding-top: 50px;


    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.login-container form {
    width: 100%;
    max-width: 450px;
}

.login-container img {
    margin-bottom: 30px;
}

.login-container form button {
    background-color: #010050;
}