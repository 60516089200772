.container-comprovante-residencia {
  /* padding-top: 50px; */
}

.container-comprovante-residencia p {
  font-size: 10px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}
