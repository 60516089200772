.score-exibicao-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.score-exibicao-container table {
    border-left: solid 1px #010050;
    border-right: solid 1px #010050;
    border-bottom: solid 1px #010050;
    border-radius: 10px;
}

.score-exibicao-container table th {
    background-color: #010050 !important;
}

.score-exibicao-container tbody tr td {
    border-top: solid 1px #010050;
}

.score-exibicao-container img {
    width: 100%;
}