.politica-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.politica-container section.info-lgpd {
    width: 100%;
    max-width: 450px;
    color: rgb(20,50,75);
}

.politica-container section.info-lgpd div.info-cabecalho {
    margin-bottom: 35px;
}

.politica-container section.info-lgpd div.info-cabecalho h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.politica-container section.info-lgpd div.info-cabecalho p {
    font-weight: 500;
    margin-bottom: 50px;
}

.politica-container section.info-lgpd div.info-texto {
    width: 100%;
    margin-bottom: 20px;
}

.politica-container section.info-lgpd div.info-texto h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.politica-container section.form {
    width: 100%;
    max-width: 350px;
}

.politica-container section.form .checkbox-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.politica-container .checkbox-concordo {
    width: 20px;
    height: 20px;
}

.politica-container label {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
}
