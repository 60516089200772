.documento-container {
  background-color: #DBE3F8 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.documento-container.no-pdf {
  background-color: #FFF !important;
}

.documento-container section.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.documento-container .content {
  background-color: inherit;
  width: 100%;
  /* margin-top: 98px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.documento-container section.form .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.documento-container .checkbox-concordo {
  width: 28px;
  height: 28px;
}

.documento-container label {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.button-doc {
  max-width: 350px;
}

.checkbox-label {
  font-size: 8px;
  text-align: left;
}

.documento-selector {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documento-selector label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  margin-right: 5px;
}

.documento-selector .selector-label {
  float: left;
}

.documento-selector .selector-items {
  float: right;
}

.documento-selector .span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #eeeeee;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;

  text-align: center;
  position: static;
  width: 34px;
  height: 36px;
  left: calc(50% - 34px / 2);
  top: calc(50% - 18px / 2);
  font-size: 20px;
  font-weight: 500;
  margin-top: 2px;
}

.documento-selector .visualizado {
  background-color: #69af1a;
  color: #fff;
}

.documento-selector .visualizado .check {
  max-width: 80%;
  height: auto;
}

.documento-selector .vigente {
  background-color: #ffeade;
  color: #e15100;
}

.documento-selector .pendente {
  color: #757575;
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .documento-selector {
    margin-top: 15px;
  }

  .documento-selector .span {
    font-size: 16px;
    width: 30px;
    height: 32px;
    left: calc(50% - 30px / 2);
    top: calc(50% - 15px / 2);
  }
}

@media screen and (max-width: 414px) {
  .documento-selector {
    margin-top: 30px;
  }
}
