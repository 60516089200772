.arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  cursor: pointer;
}

.arrow-top {
  transform: rotate(-45deg);
}
.arrow-bottom {
  transform: rotate(135deg);
}

.dados-th {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dados-th span.titulo {
  width: 95%;
}
