.redefinir-senha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.redefinir-senha-container form {
  width: 100%;
  max-width: 450px;
}
