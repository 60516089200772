.pdf-container {
  /* margin: 10px 0; */
  /* padding: 10px; */
  margin-top: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdf-container {
  /* margin: 1em 0; */
}

.pdf-container.not-small {
  margin-bottom: 98px;
}

.pdf-container .react-pdf__Document {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdf-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-container.not-small .react-pdf__Page canvas {
  width: 800px !important;
  height: auto !important;
}

.pdf-container.small .react-pdf__Page canvas {
  width: auto !important;
  height: calc(100vh - 250px) !important;
}

.pdf-container .react-pdf__Page__svg svg {
  border-radius: 8px;
  max-width: 400px;
  width: 90vw;
  height: auto;
  display: block;
  background-color: white;
}
.pdf-container .react-pdf__message {
  padding: 20px;
  color: white;
}

.pdf-container .react-pdf__Page__textContent {
  left: auto !important;
  display: none;
}

.pdf-container .page-controls {
  --position: absolute;
  --bottom: 5%;
  --left: 50%;
  background: white;
  --opacity: 0;
  --transform: translateX(-50%);
  --transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  margin-top: 8px;
}

.pdf-container .page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pdf-container .page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.pdf-container .page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}


@media screen and (max-width:800px) {
  .pdf-container {
    margin-top: 30px;
  }
}
