.biometria-container {
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  margin: 0 auto;
  border: none;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

/*Modal style*/

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-container .selfie-card {
  margin-bottom: 40px;
}

.modal-container .selfie-card img {
  width: auto;
  height: auto;
}

.modal-container .selfie-card p {
  font-size: 20px;
  font-weight: 700;
  color: #e15100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .selfie-dicas {
  width: 100%;
  margin-bottom: 30px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.modal-container .selfie-dicas img,
.modal-container .selfie-dicas svg {
  margin-right: 40px;
  max-width: 25px;
}

.modal-container .selfie-dicas p {
  font-weight: 600;
  font-size: 14px;
}