.passos-container {
  position: relative;
}

.passos-container .conteudo h1 {
  margin-bottom: 25px;
}

.passos-container .conteudo h2 {
  margin-bottom: 35px;
}

.passos-container .conteudo p.info-adicional {
  /* font-size: 16px;
  color: #757575;
  position: absolute;
  bottom: 0;
  margin-bottom: 35px; */
  font-size: 16px;
    color: #757575;
    position: unset;
    bottom: 0;
    margin-bottom: unset;
    margin-top: 35px;
}

.passos-container .conteudo p.info-adicional a {
  text-decoration: underline;
  color: #212121;
}

.passos-container .conteudo .instrucoes-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.passos-container .conteudo .instrucoes-container .instrucao {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 20px;
  width: 2px;
  height: calc(100% - 15px);
  background-color: var(--dynamic-line-color, #2b3ea1);
}

.passos-container .conteudo .instrucoes-container .instrucao .instrucao-icone {
  border: 1px solid #2b3ea1;
  border-radius: 10px;
  padding: 7px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao
  .titulo {
  font-weight: 700;
  font-size: 16px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao
  .subtitulo {
  color: #616161;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .passos-container {
    padding-bottom: 100px !important;
  }

  /* .passos-container .conteudo p.info-adicional {
    font-size: 16px;
    color: #757575;
    position: unset;
    bottom: 0;
    margin-bottom: unset;
    margin-top: 35px;
  } */
}
