.verificacao-container fieldset {
  width: 100%;
  margin-bottom: 0;
}

.verificacao-container h5 {
  margin-bottom: 25px;
}

@media screen and (max-width: 800px) {
  .verificacao-container h5 {
    font-size: 16px !important;
  }
}
