.autenticacao-portal-container section.form {
  width: 100%;
  max-width: 450px;
}

.autenticacao-portal-container section.form fieldset.fs-senha {
  margin-bottom: 0;
}

.autenticacao-portal-container section.form a.redefine-senha {
  color: #00f;
  float: right;
  cursor: pointer;
  margin-bottom: 20px;
}
