.footer-bem {
  padding: 0 25px;
  position: fixed;
  width: 100%;
  height: 25px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.5);
  background: #e15100;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.footer-bem .footer-bem-info {
  color: #fff;
  margin: 3px 0;
  text-align: center;
}

.footer-bem .footer-bem-info span {
  font-size: 0.7rem;
}

.footer-links {
  background: linear-gradient(355.64deg, #5889FA 4.35%, #2B3EA1 67.15%);
  color: #ffffff;
}

.footer-links .agrupador-footer {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
}

.footer-links .agrupador-footer .links {
  margin-bottom: 20px;
}

.footer-links .agrupador-footer .links .menu {
  list-style: none outside;
}

.footer-links .agrupador-footer .links .menu li {
  padding-bottom: 12px;
}

.footer-links .agrupador-footer .links .menu li a {
  font-size: 18px;
  line-height: 19px;
  font-weight: 700;
}

.footer-links .agrupador-footer .links .menu li a:hover {
  text-decoration: underline;
}

.footer-links .agrupador-footer .contatos.borda-direita {
  border-right: 4px solid rgba(255, 255, 255, 0.1);
  padding-right: 30px;
}

.footer-links .agrupador-footer .contatos h6 {
  width: 200px;
  color: #fa9547;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.footer-links .agrupador-footer .contatos h5 {
  margin-bottom: 20px;
  line-height: 1.3em;
  color: #c3c3c3;
}

.footer-links .agrupador-footer .contatos h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  letter-spacing: 0px;
}

.footer-links .direitos {
  margin-top: 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .footer-links .agrupador-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-links .agrupador-footer .contatos.borda-direita {
    border: 0;
    padding: 0;
    border-bottom: 4px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 30px;
    margin-bottom: 35px;
  }
}
