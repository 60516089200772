.usuario-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.usuario-container section.form {
    width: 100%;
    max-width: 450px;
}

.usuario-container section.form h2 {
  color: rgb(20, 50, 75);
}

.usuario-container form button {
  max-width: 450px;
}
