.liveness-container {
  background-color: inherit;
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;
  color: white;
  font-size: 100%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border: none;
}

.audit-trail-overlay {
  --position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.audit-trail-image {
  margin-top: 10px;
  max-width: 200px;
  max-height: auto;
}

/* start of styles for overlay rect that is placed on top of ZoOm, button and checkbox, and fancy background fade container */
#theme-transition-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  pointer-events: none;
}

#theme-transition-overlay-img {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
}

.theme-transition-overlay__mobile {
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.theme-transition-overlay__mobile > img {
  height: 100% !important;
  width: 100% !important;
}

.theme-transition-overlay__default {
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.theme-transition-overlay__well-rounded {
  background-color: rgb(3, 199, 178);
}

.theme-transition-overlay__bitcoin-exchange,
.theme-transition-overlay__bitcoin-exchange,
.theme-transition-overlay__ekyc,
.theme-transition-overlay__sample-bank {
  background-color: white;
}

.theme-transition-overlay__well-rounded > img {
  transform: translate(-55%, -45%) scale(0.9);
}

.theme-transition-overlay__bitcoin-exchange > img {
  transform: translate(-55%, -55%) scale(0.9);
}

.theme-transition-overlay__ekyc > img {
  transform: translate(-45%, -45%) scale(0.9);
}

.theme-transition-overlay__sample-bank > img {
  transform: translate(-45%, -45%) scale(0.9);
}

.theme-transition-overlay__well-rounded > img,
.theme-transition-overlay__bitcoin-exchange > img,
.theme-transition-overlay__ekyc > img,
.theme-transition-overlay__sample-bank > img {
  width: inherit;
  height: inherit;
}

#controls {
  width: 640px;
  padding: 20px 0;
  max-width: 100%;
  margin: 0 auto;
  border: 2px solid rgb(65, 127, 178);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  background-color: white;
}

.big-button {
  width: 40%;
  height: 40px;
  margin: 10px auto 0;
  color: white;
  background: rgb(65, 127, 178);
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.medium-button {
  width: 40%;
  height: 30px;
  margin: 10px auto 0;
  color: white;
  background: rgb(65, 127, 178);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.big-button:disabled,
.big-button[disabled],
.medium-button:disabled,
.medium-button[disabled] {
  cursor: not-allowed;
  background: rgba(65, 127, 178, 0.4) !important;
  color: white !important;
}

@media (hover: hover) {
  .big-button:hover:enabled,
  .medium-button:hover:enabled {
    background-color: rgb(57, 110, 153);
    color: white !important;
    transition: all 0.3s ease-out;
  }
}

#status {
  color: rgb(64, 127, 178);
  margin: 20px auto 0;
  display: none;
  font-size: 18px;
  background-color: rgba(65, 127, 178, 0.15);
  width: 90%;
  --display: block;
  border-radius: 4px;
  padding: 8px;
}

.wrapping-box-container {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: auto;
  color: #fff;
  padding: 10px;
}

#custom-logo-container {
  margin: 20px auto;
}

#custom-logo-container img {
  max-height: 70px;
  width: auto;
  height: auto;
  text-align: center;
  position: relative;
  padding-right: 4px;
}

button:disabled {
  cursor: not-allowed;
  color: #ddd;
  border-color: #ddd;
}

a {
  text-decoration: none;
  color: inherit;
}

.liveness-container .button-biometria {
  max-width: 400px;
}
