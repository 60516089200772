.solicitacao-acesso-container {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  padding-top: 60px;
}

.solicitacao-acesso-container p {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}

.solicitacao-acesso-container p b {
  color: #2B3EA1;
}

.solicitacao-acesso-container button {
  max-width: 450px;
}

.solicitacao-acesso-container .container-gifs {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 40px;
}

.solicitacao-acesso-container .container-gifs svg:first-child{
  margin-right: 20px;
}
