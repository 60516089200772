@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Inter, sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  color: #212121;
}

input {
  font: 500 18px Inter, sans-serif;
}

button,
textarea {
  font: 400 18px Inter, sans-serif;
}

button {
  cursor: pointer;
}

form input,
input[type="tel"] {
  width: 100%;
  height: 48px;
  color: #212121;
  border: none;
  border-radius: 8px;
  padding: 0px 0px 5px 10px;
  margin: 0;
}

.container h1 {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.container h2 {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.container h3 {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  text-align: center;
}

.button-bem {
  width: 100%;
  height: 64px;
  background: #2b3ea1;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: filter 0.2s;
}

.button-bem-v2 {
  height: 48px;
  background: #2b3ea1;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  margin-top: 35px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: filter 0.2s;
  padding: 0 60px;
}

.button-bem-v2.secundario {
  border: 1px solid #2b3ea1;
  background-color: white;
  color: #2b3ea1;
}

.button-bem:hover {
  filter: brightness(90%);
}

.button-bem:disabled {
  background: #eeeeee;
  color: #9e9e9e;
}

.button-bem-v2:hover {
  filter: brightness(90%);
}

.button-bem-v2:disabled:hover {
  filter: unset;
}

.button-bem-v2:disabled {
  background: #eeeeee !important;
  color: #9e9e9e !important;
}

.enabled {
  display: block;
}

.hidden {
  display: none;
}

.input-erro {
  color: red;
}

.input-erro legend {
  color: red;
}

.container {
  padding: 10px;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1120px) {
  body {
    background-color: #ebebeb;
  }
  /* h2 {
    margin-bottom: 45px;
  } */
  .container {
    border: solid 1px #cccccc;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .button-bem-v2 {
    font-size: 12px;
  }
}

@media screen and (min-width: 800px) {
  .container {
    align-items: center;
    /* min-height: 92vh; */
  }
}

@media screen and (max-width: 800px) {
  .header {
    background-color: #ffffff;
    position: fixed;
    z-index: 999;
  }

  .button-bem-v2:last-child {
    margin-top: 10px;
  }

  .button-bem-v2 {
    margin-top: 0;
    width: 100%;
  }

  /* .container {
    min-height: 92vh;
  } */
}

@media screen and (max-width: 500px) {
  .button-bem-v2 {
    width: 100%;
  }
}

@media screen and (max-height: 680px) {
  /* h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 16px;
  } */

  /* .button-bem {
    background-color: green;
  } */
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .button-bem {
    font-size: 14px;
  }
}

.table-group {
  border-left: solid 1px #e15100;
  border-right: solid 1px #e15100;
  border-bottom: solid 1px #e15100;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 1120px;
}

.table-group.retratil {
  border: 0;
}

.table-group.retratil thead tr th {
  border-radius: 10px;
}

.table-group thead tr th {
  font-size: 14px;
  font-weight: normal;
  padding: 12px;
  text-align: left;
  background-color: #e15100;
  color: white;
}

.table-group thead tr th:first-child {
  border-top-left-radius: 10px;
}

.table-group thead tr th:last-child {
  border-top-right-radius: 10px;
}

.table-group tbody tr td {
  font-weight: 400;
  font-size: 16px;
  padding: 12px;
  border-top: solid 1px #e15100;
  width: 50%;
}

/*
TABLE V2
*/

.table-group-v2 {
  border-left: solid 1px #dee0e1;
  border-right: solid 1px #dee0e1;
  border-bottom: solid 1px #dee0e1;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.table-group-v2.retratil {
  border: 0;
}

.table-group-v2.retratil thead tr th {
  border-radius: 10px;
}

.table-group-v2 thead tr th {
  font-size: 14px;
  font-weight: normal;
  padding: 12px;
  text-align: left;
  background-color: #2b3ea1;
  color: white;
}

.table-group-v2 thead tr th:first-child {
  border-top-left-radius: 10px;
}

.table-group-v2 thead tr th:last-child {
  border-top-right-radius: 10px;
}

.table-group-v2 tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.table-group-v2 tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table-group-v2.arredonda-ultimo tbody tr:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-group-v2 tbody tr td {
  font-weight: 400;
  font-size: 16px;
  padding: 12px;
  border-top: solid 1px #dee0e1;
  background-color: #fff;
}

/*
TABLE V2
*/

.center {
  text-align: center;
}

fieldset {
  padding: 0px;
  border-radius: 5px;
  border: solid 1px;
  margin-bottom: 20px;
}

fieldset legend {
  margin-left: 10px;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbbb7;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

.header {
  align-content: center;
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.header img.logout-portal {
  margin-right: 15px;
}

.header img.logo {
  position: absolute;
  width: 18%;
}

.header img:last-child {
  margin-left: auto;
  cursor: pointer;
}

.header img.audio {
  width: 20px;
  position: absolute;
  right: 5px;
}

.header #logo-b {
  width: 100%;
  height: auto;
  max-width: 35px;
  position: absolute;
  left: 5px;
}

.header #logo-b.fundacao {
  max-width: 160px;
}

.header nav.menu-assinatura ul {
  display: flex;
}

.header nav.menu-assinatura ul li {
  list-style: none;
}

.header nav.menu-assinatura ul li a {
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0.4rem 2.4rem;
  transition: all 250ms linear 0s;
}

.header nav.menu-assinatura ul li a:hover {
  background: rgba(80, 78, 78, 0.15);
}

.menu-section nav.menu-assinatura ul a.menu-highlight {
  background: rgba(80, 78, 78, 0.15);
}

.container-header-progresso {
  display: flex;
  flex-direction: column;
}

@media (max-width: 540px) {
  .header {
    justify-content: space-between;
  }

  .header #logo-b {
    position: relative;
  }

  nav.menu-assinatura {
    display: none;
  }

  .one,
  .two,
  .three {
    background-color: #a19595;
    height: 1px;
    width: 100%;
    margin: 6px auto;

    transition-duration: 0.3s;
  }

  .menu-toggle {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
  }

  .menu-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-section.on {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #e15100;

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav.menu-assinatura {
    display: block;
  }

  .menu-section.on .menu-toggle {
    position: absolute;
    right: 5px;
    top: 10px;
  }

  .menu-section.on .menu-toggle .one,
  .menu-section.on .menu-toggle .two,
  .menu-section.on .menu-toggle .three {
    background-color: #fff;
  }

  .menu-section.on .menu-toggle .one {
    transform: rotate(45deg) translate(4px, 5px);
  }

  .menu-section.on .menu-toggle .two {
    opacity: 0;
  }

  .menu-section.on .menu-toggle .three {
    transform: rotate(-45deg) translate(4px, -7px);
  }

  .menu-section.on nav.menu-assinatura ul {
    text-align: center;
    display: block;
  }

  .menu-section.on nav.menu-assinatura ul a {
    color: #fff;
    transition-duration: 0.5s;
    font-size: 0.8rem;
    line-height: 4rem;
    display: block;
    margin: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .header #logo-b {
    max-width: 30px;
    display: block;
  }

  .header img.logo {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .header img.logo {
    width: 50%;
  }

  .header #logo-b {
    max-width: 28px;
  }

  .header #logo-b.fundacao {
    max-width: 130px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .header img.logo {
    width: 60%;
  }

  .header #logo-b {
    max-width: 25px;
  }

  .header #logo-b.fundacao {
    max-width: 100px;
  }
}

.header-bemsign {
  background: linear-gradient(73.16deg, #ffbe2d 11.41%, #ff6518 74.48%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.header-bemsign img {
  width: 100%;
  max-height: 80px;
}

.passos-progresso {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
}

.passos-progresso span {
  height: 4px;
  width: 100%;
  border: 1px solid #ccc;
  margin-right: 2px;
}

.passos-progresso span:last-child {
  margin: 0;
}

.passos-progresso span.highlight {
  background: linear-gradient(162.43deg, #3249af 49.02%, #507cea 107.26%);
}

@media screen and (max-width: 800px) {
  .passos-progresso {
    margin-top: 6vh;
    position: fixed;
  }
}

.footer-bem {
  padding: 0 25px;
  position: fixed;
  width: 100%;
  height: 25px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.5);
  background: #e15100;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.footer-bem .footer-bem-info {
  color: #fff;
  margin: 3px 0;
  text-align: center;
}

.footer-bem .footer-bem-info span {
  font-size: 0.7rem;
}

.footer-links {
  background: linear-gradient(355.64deg, #5889FA 4.35%, #2B3EA1 67.15%);
  color: #ffffff;
}

.footer-links .agrupador-footer {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
}

.footer-links .agrupador-footer .links {
  margin-bottom: 20px;
}

.footer-links .agrupador-footer .links .menu {
  list-style: none outside;
}

.footer-links .agrupador-footer .links .menu li {
  padding-bottom: 12px;
}

.footer-links .agrupador-footer .links .menu li a {
  font-size: 18px;
  line-height: 19px;
  font-weight: 700;
}

.footer-links .agrupador-footer .links .menu li a:hover {
  text-decoration: underline;
}

.footer-links .agrupador-footer .contatos.borda-direita {
  border-right: 4px solid rgba(255, 255, 255, 0.1);
  padding-right: 30px;
}

.footer-links .agrupador-footer .contatos h6 {
  width: 200px;
  color: #fa9547;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.footer-links .agrupador-footer .contatos h5 {
  margin-bottom: 20px;
  line-height: 1.3em;
  color: #c3c3c3;
}

.footer-links .agrupador-footer .contatos h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  letter-spacing: 0px;
}

.footer-links .direitos {
  margin-top: 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .footer-links .agrupador-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-links .agrupador-footer .contatos.borda-direita {
    border: 0;
    padding: 0;
    border-bottom: 4px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 30px;
    margin-bottom: 35px;
  }
}

.loading-container {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 65px); */
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 60px;
}

.overlay-container {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.52);
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.autenticacao-container section.form {
  width: 100%;
  max-width: 450px;
}

.autenticacao-container h4,
.autenticacao-container h5 {
  margin-top: 25px;
}

.autenticacao-container section.form h1,
.autenticacao-container section.form h2 {
  color: rgb(20, 50, 75);
}

.modal-container button.recomecar-assinatura {
  border: 1px solid rgb(20, 50, 75);
  background-color: white;
  color: rgb(20, 50, 75);
}

.modal-container h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-container #volume-aviso {
  width: 200px;
}

.botao-fixado {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.botao-fixado button {
  max-width: 450px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.botao-fixado button img {
  margin-left: 10px;
}

.botao-fixado.doc-wide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .button-bem-v2 {
    margin: 0;
    width: 150px;
  }
}

.botao-fixado.rm-mg-button {
  .button-bem-v2 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .botao-fixado {
    width: 100%;
  }
}

.timer-container {
  background-color: rgb(20, 50, 75);
  margin-top: 30px;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 15px;
}

.timer-container span {
  color: #fff;
  font-size: 30px;
  display: flex;
  min-width: 40px;
  justify-content: center;
}

.webcam {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.webcam h1 {
  font-size: 20px;
  height: 50px;
}

.webcam h2 {
  font-size: 14px;
}

.webcam button,
.previsao-buttons {
  width: 100%;
  max-width: 450px;
}

.webcam .previsao-buttons button.tirar-novamente {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.webcam .camera-foto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.webcam .camera-foto img.previsao-foto {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.webcam .camera-foto .group-template-camera {
  max-width: 450px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  position: relative;
}

.webcam .camera-foto .group-template-camera video {
  clip-path: ellipse(90px 140px at 50% 50%);

  width: 100%;
  height: 100%;
}

.webcam .mensagem-instrucao {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(20, 50, 75);
  padding: 15px;
  width: 100%;
}

.webcam .mensagem-instrucao span {
  color: #fff;
  font-weight: 700;
}

.webcam .logo-empresa img {
  max-width: 35%;
  margin-top: 15px;
}

.logo-fundacao {
  min-width: 300px;
  max-height: 300px;
}

/************************************************************************
************************************************************************
************************************************************************
************************************************************************/

@keyframes animacao {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 0.9;
  }
}

.indicador-instrucao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
}

.seta {
  border-color: transparent;
  border-style: solid;
  border-width: 0 2em;
  display: block;
  height: 0;
  /* margin:10em auto; */
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;
}

.animacao {
  animation: animacao 0.6s infinite alternate ease-in-out;
  -webkit-animation: animacao 0.6s infinite alternate ease-in-out;
  border-bottom: 2em solid red;
}

.seta-direita {
  align-items: center;
  justify-content: flex-end !important;
}

.seta-esquerda {
  align-items: center;
  justify-content: flex-start !important;
}

.seta-cima {
  align-items: flex-start;
  justify-content: center !important;
}

.seta-baixo {
  align-items: flex-end;
  justify-content: center !important;
}

/************************************************************************
************************************************************************
************************************************************************
************************************************************************/

@media screen and (max-width: 414px) {
  .webcam .camera-foto {
    max-height: 300px;
  }

  .webcam .camera-foto video {
    height: 150px;
  }

  .webcam .camera-foto img.template-face {
    width: 140px;
  }

  .webcam h1 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .webcam h2 {
    font-size: 1em;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .webcam .camera-foto video {
    height: 140px;
  }

  .webcam .camera-foto img.template-face {
    width: 130px;
  }
}

.liveness-container {
  background-color: inherit;
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;
  color: white;
  font-size: 100%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border: none;
}

.audit-trail-overlay {
  --position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.audit-trail-image {
  margin-top: 10px;
  max-width: 200px;
  max-height: auto;
}

/* start of styles for overlay rect that is placed on top of ZoOm, button and checkbox, and fancy background fade container */
#theme-transition-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  pointer-events: none;
}

#theme-transition-overlay-img {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
}

.theme-transition-overlay__mobile {
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.theme-transition-overlay__mobile > img {
  height: 100% !important;
  width: 100% !important;
}

.theme-transition-overlay__default {
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.theme-transition-overlay__well-rounded {
  background-color: rgb(3, 199, 178);
}

.theme-transition-overlay__bitcoin-exchange,
.theme-transition-overlay__bitcoin-exchange,
.theme-transition-overlay__ekyc,
.theme-transition-overlay__sample-bank {
  background-color: white;
}

.theme-transition-overlay__well-rounded > img {
  transform: translate(-55%, -45%) scale(0.9);
}

.theme-transition-overlay__bitcoin-exchange > img {
  transform: translate(-55%, -55%) scale(0.9);
}

.theme-transition-overlay__ekyc > img {
  transform: translate(-45%, -45%) scale(0.9);
}

.theme-transition-overlay__sample-bank > img {
  transform: translate(-45%, -45%) scale(0.9);
}

.theme-transition-overlay__well-rounded > img,
.theme-transition-overlay__bitcoin-exchange > img,
.theme-transition-overlay__ekyc > img,
.theme-transition-overlay__sample-bank > img {
  width: inherit;
  height: inherit;
}

#controls {
  width: 640px;
  padding: 20px 0;
  max-width: 100%;
  margin: 0 auto;
  border: 2px solid rgb(65, 127, 178);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  background-color: white;
}

.big-button {
  width: 40%;
  height: 40px;
  margin: 10px auto 0;
  color: white;
  background: rgb(65, 127, 178);
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.medium-button {
  width: 40%;
  height: 30px;
  margin: 10px auto 0;
  color: white;
  background: rgb(65, 127, 178);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.big-button:disabled,
.big-button[disabled],
.medium-button:disabled,
.medium-button[disabled] {
  cursor: not-allowed;
  background: rgba(65, 127, 178, 0.4) !important;
  color: white !important;
}

@media (hover: hover) {
  .big-button:hover:enabled,
  .medium-button:hover:enabled {
    background-color: rgb(57, 110, 153);
    color: white !important;
    transition: all 0.3s ease-out;
  }
}

#status {
  color: rgb(64, 127, 178);
  margin: 20px auto 0;
  display: none;
  font-size: 18px;
  background-color: rgba(65, 127, 178, 0.15);
  width: 90%;
  --display: block;
  border-radius: 4px;
  padding: 8px;
}

.wrapping-box-container {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: auto;
  color: #fff;
  padding: 10px;
}

#custom-logo-container {
  margin: 20px auto;
}

#custom-logo-container img {
  max-height: 70px;
  width: auto;
  height: auto;
  text-align: center;
  position: relative;
  padding-right: 4px;
}

button:disabled {
  cursor: not-allowed;
  color: #ddd;
  border-color: #ddd;
}

a {
  text-decoration: none;
  color: inherit;
}

.liveness-container .button-biometria {
  max-width: 400px;
}

.biometria-container {
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  margin: 0 auto;
  border: none;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

/*Modal style*/

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-container .selfie-card {
  margin-bottom: 40px;
}

.modal-container .selfie-card img {
  width: auto;
  height: auto;
}

.modal-container .selfie-card p {
  font-size: 20px;
  font-weight: 700;
  color: #e15100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .selfie-dicas {
  width: 100%;
  margin-bottom: 30px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.modal-container .selfie-dicas img,
.modal-container .selfie-dicas svg {
  margin-right: 40px;
  max-width: 25px;
}

.modal-container .selfie-dicas p {
  font-weight: 600;
  font-size: 14px;
}
.pdf-container {
  /* margin: 10px 0; */
  /* padding: 10px; */
  margin-top: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdf-container {
  /* margin: 1em 0; */
}

.pdf-container.not-small {
  margin-bottom: 98px;
}

.pdf-container .react-pdf__Document {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdf-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-container.not-small .react-pdf__Page canvas {
  width: 800px !important;
  height: auto !important;
}

.pdf-container.small .react-pdf__Page canvas {
  width: auto !important;
  height: calc(100vh - 250px) !important;
}

.pdf-container .react-pdf__Page__svg svg {
  border-radius: 8px;
  max-width: 400px;
  width: 90vw;
  height: auto;
  display: block;
  background-color: white;
}
.pdf-container .react-pdf__message {
  padding: 20px;
  color: white;
}

.pdf-container .react-pdf__Page__textContent {
  left: auto !important;
  display: none;
}

.pdf-container .page-controls {
  --position: absolute;
  --bottom: 5%;
  --left: 50%;
  background: white;
  --opacity: 0;
  --transform: translateX(-50%);
  --transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  margin-top: 8px;
}

.pdf-container .page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pdf-container .page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.pdf-container .page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}


@media screen and (max-width:800px) {
  .pdf-container {
    margin-top: 30px;
  }
}

.template-container {
  width: 100%;
  /* max-width: 80%; */
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.template-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 15px;
}
.template-instrucoes {
  background-color: #e15100;
  padding: 40px 4px 20px 4px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.div-documento {
  border: 0.2px solid #55555555;
  padding: 10px;
  margin: 20px;
  box-shadow: 2px 5px 4px 5px #33333333;
}
.div-documento p {
  padding: 10px;
  font-size: 15px;
  text-align: justify;
}
.text-align-right {
  text-align: right !important;
}

@media screen and (max-width: 414px) {
  .template-container {
    max-width: 100%;
  }
}
.resumo-ccb-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.resumo-ccb-container h1 {
  margin-bottom: 40px;
  max-width: 260px;
}

.resumo-ccb-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 40px;
}

.resumo-ccb-container .resumo-valor-ccb {
  margin-bottom: 30px;
}

.resumo-ccb-container .resumo-valor-ccb label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
}

.resumo-ccb-container .resumo-valor-ccb div {
  background-color: #e15100;
  padding: 15px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-ccb-container .resumo-valor-ccb span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-ccb-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 220px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
}

.resumo-ccb-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 50%;
}

.resumo-ccb-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
}

.resumo-ccb-container .resumo-informacoes-adicionais span {
  color: #e15100;
  font-weight: 700;
  font-size: 15px;
}

.resumo-ccb-container button {
  max-width: 450px;
}

@media screen and (max-width: 414px) {
  .resumo-ccb-container .resumo-valor-ccb div {
    width: auto;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .resumo-ccb-container {
    max-width: 160px;
  }

  .resumo-ccb-container .resumo-valor-ccb div {
    max-width: 160px;
    min-width: 160px;
  }

  .resumo-ccb-container .resumo-informacoes-adicionais {
    min-width: 160px;
    max-width: 160px;
  }

  .resumo-ccb-container .resumo-valor-ccb span {
    font-size: 20px;
  }

  .resumo-ccb-container h1 {
    max-width: 160px;
  }
}

.container-ccb {
  /* padding-top: 50px; */
}

.container-ccb .p-cabecalho {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: left;
}
.container-ccb .p-condicoes {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: justify;
}
.container-ccb .div-condicoes {
  padding: 0px;
}
.container-ccb .div-condicoes > p {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: justify;
}

#valorasercreditado .titulo {
  font-weight: 700;
}

#valorasercreditado tbody tr td {
  width: 33%;
}

.container-comprovante-residencia {
  /* padding-top: 50px; */
}

.container-comprovante-residencia p {
  font-size: 10px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

.arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  cursor: pointer;
}

.arrow-top {
  transform: rotate(-45deg);
}
.arrow-bottom {
  transform: rotate(135deg);
}

.dados-th {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dados-th span.titulo {
  width: 95%;
}

.termo-autorizacao-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.termo-autorizacao-container p {
  font-size: 10px;
}

@media screen and (max-width: 414px) {
  .termo-autorizacao-container {
    /* padding-top: 50px; */
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .termo-autorizacao-container-container {
    /* padding-top: 50px; */
  }
}

.container-solicitacao-portabilidade {
  /* padding-top: 50px; */
}

.container-solicitacao-portabilidade p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

.container-aumento-portabilidade p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

@media screen and (max-height: 680px) {
  .container-aumento-portabilidade {
    /* padding-top: 50px; */
  }
}

.resumo-ccb-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.resumo-ccb-container h1 {
  margin-bottom: 30px;
  max-width: 260px;
}

.resumo-ccb-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 30px;
  font-weight: 700;
}

.resumo-ccb-container .resumo-valor-ccb {
  margin-bottom: 20px;
  width: 100%;
}

.resumo-ccb-container .resumo-valor-ccb label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  margin-left: 0;
}

.resumo-ccb-container .resumo-valor-ccb div {
  background-color: #e15100;
  padding: 13px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-ccb-container .resumo-valor-ccb span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-ccb-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 100%;
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
}

.resumo-ccb-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 100%;
}

.resumo-ccb-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
  margin: 0;
}

.resumo-ccb-container .resumo-informacoes-adicionais span {
  color: #e15100;
  font-weight: 700;
  font-size: 15px;
}

.resumo-ccb-container button {
  max-width: 450px;
}

.resumo-ccb-container .tabela-antecipacoes{
  width: 100%;
  text-align: center;
}

.resumo-ccb-container .tabela-antecipacoes label {
  font-weight: 700;
}

.resumo-ccb-container .tabela-antecipacoes th {
  padding: 5px;
  text-align: center;
}

.resumo-ccb-container .tabela-antecipacoes table {
  margin-bottom: 0;
}

.resumo-ccb-container .tabela-antecipacoes td {
  width: 33.33%;
  padding: 6px 2px;
}

@media screen and (max-width: 800px) {
  .resumo-ccb-container{
    padding-top: 20px;
  }
}

.container-termo-adesao {
  /* padding-top: 50px; */
}

.container-termo-adesao p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 10px;
  font-weight: 400;
}

.text-align-right {
  text-align: right !important;
}

.container-termo-adesao .div-condicoes > p {
  font-size: 15px !important;
  padding: 0px !important;
  text-align: center;
}

.container-termo-adesao .lista-condicoes {
  padding: 0 5px;
  margin-top: 20px;
}

.container-termo-adesao ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.container-termo-adesao ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.container-termo-adesao ol > li > span {
  font-weight: 700;
  margin-bottom: 10px;
}

.container-termo-adesao ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.container-termo-adesao .container-termo-adesao li ol > li {
  margin: 0;
}

.container-termo-adesao li ol > li:before {
  content: counters(item, ".") " ";
}

.container-termo-adesao table tbody tr td input[type="checkbox"] {
  margin-right: 10px;
  width: 10px;
}

.container-termo-adesao table .info-conta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 8px;
}

.container-termo-adesao table .info-conta thead tr th {
  border-radius: unset;
}

.container-termo-adesao table .info-conta thead tr th:first-child {
  border-top-left-radius: 10px;
}

.container-termo-adesao table .info-conta thead tr th:last-child {
  border-top-right-radius: 10px;
}

.container-termo-adesao table .info-conta tbody tr td {
  width: auto;
  border: none;
}

.container-termo-adesao table .info-conta .titulo {
  font-weight: 700;
}

.autorizacao-liquidar-divida-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 414px) {
  .autorizacao-liquidar-divida-container {
    /* padding-top: 50px; */
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .autorizacao-liquidar-divida-container {
    /* padding-top: 50px; */
  }
}

.container-contrato-seguro {
  /* padding-top: 50px; */
}

.container-contrato-seguro p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
}

.resumo-fgts-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.resumo-fgts-container h1 {
  margin-bottom: 30px;
  max-width: 260px;
}

.resumo-fgts-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 30px;
  font-weight: 700;
}

.resumo-fgts-container .resumo-valor-fgts {
  margin-bottom: 20px;
  width: 100%;
}

.resumo-fgts-container .resumo-valor-fgts label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  margin-left: 0;
}

.resumo-fgts-container .resumo-valor-fgts div {
  background-color: #e15100;
  padding: 13px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-fgts-container .resumo-valor-fgts span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-fgts-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 100%;
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
}

.resumo-fgts-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 50%;
}

.resumo-fgts-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
  margin: 0;
}

.resumo-fgts-container .resumo-informacoes-adicionais span {
  color: #2b3ea1;
  font-weight: 700;
  font-size: 15px;
}

.resumo-fgts-container button {
  max-width: 450px;
}

.resumo-fgts-container .tabela-antecipacoes{
  width: 100%;
  text-align: center;
}

.resumo-fgts-container .tabela-antecipacoes label {
  font-weight: 700;
}

.resumo-fgts-container .tabela-antecipacoes th {
  padding: 5px;
  text-align: center;
}

.resumo-fgts-container .tabela-antecipacoes table {
  margin-bottom: 0;
}

.resumo-fgts-container .tabela-antecipacoes td {
  width: 33.33%;
  padding: 6px 2px;
}

@media screen and (max-width: 800px) {
  .resumo-fgts-container{
    padding-top: 20px;
  }
}

.container-termo-adesao-fgts {
  /* padding-top: 50px; */
}

.container-termo-adesao-fgts table tbody tr td input[type=checkbox] {
  margin-right: 10px;
  width: 10px;
}

.container-termo-adesao-fgts table .info-conta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 8px;
}

.container-termo-adesao-fgts table .info-conta thead tr th {
  border-radius: unset;
}

.container-termo-adesao-fgts table .info-conta thead tr th:first-child {
  border-top-left-radius: 10px;
}

.container-termo-adesao-fgts table .info-conta thead tr th:last-child {
  border-top-right-radius: 10px;
}

.container-termo-adesao-fgts table .info-conta tbody tr td {
  width: auto;
  border: none;
}

.container-termo-adesao-fgts table .info-conta .titulo {
  font-weight: 700;
}

.container-termo-adesao-fgts .antecipacoes span {
  display: block;
}

.container-termo-adesao-fgts .antecipacao-small {
  display: table-row;
}

.container-termo-adesao-fgts .lista-condicoes {
  padding: 0 5px;
  margin-top: 20px;
}

.container-termo-adesao-fgts ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.container-termo-adesao-fgts ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.container-termo-adesao-fgts ol > li > span {
  font-weight: 700;
  margin-bottom: 10px;
}

.container-termo-adesao-fgts ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.container-termo-adesao-fgts .container-termo-adesao li ol > li {
  margin: 0;
}

.container-termo-adesao-fgts li ol > li:before {
  content: counters(item, ".") " ";
}

@media screen and (max-width: 465px) {
  .container-termo-adesao-fgts .antecipacao-small {
    display: table-row;
  }

  .container-termo-adesao-fgts .antecipacao-small td {
    width: 100%;
  }

  .container-termo-adesao-fgts .antecipacao-large {
    display: none;
  }
}

.resumo-figital-container {
  /* padding-top: 50px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;
}

.resumo-figital-container h1 {
  margin-bottom: 30px;
  max-width: 260px;
}

.resumo-figital-container h2 {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  margin-bottom: 30px;
  font-weight: 700;
}

.resumo-figital-container .resumo-valor-figital {
  margin-bottom: 20px;
  width: 100%;
}

.resumo-figital-container .resumo-valor-figital label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  margin-left: 0;
}

.resumo-figital-container .resumo-valor-figital div {
  background-color: #e15100;
  padding: 13px;
  border-radius: 8px;
  min-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.resumo-figital-container .resumo-valor-figital span {
  font-size-adjust: 0.58;
  font-size: 30px;
  color: #fff;
}

.resumo-figital-container .resumo-informacoes-adicionais {
  min-width: 110px;
  width: 100%;
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
}

.resumo-figital-container .resumo-informacoes-adicionais div {
  margin-bottom: 10px;
  max-width: 50%;
}

.resumo-figital-container .resumo-informacoes-adicionais label {
  display: block;
  font-weight: 700;
  margin: 0;
}

.resumo-figital-container .resumo-informacoes-adicionais span {
  color: #e15100;
  font-weight: 700;
  font-size: 15px;
}

.resumo-figital-container button {
  max-width: 450px;
}

.resumo-figital-container .tabela-antecipacoes{
  width: 100%;
  text-align: center;
}

.resumo-figital-container .tabela-antecipacoes label {
  font-weight: 700;
}

.resumo-figital-container .tabela-antecipacoes th {
  padding: 5px;
  text-align: center;
}

.resumo-figital-container .tabela-antecipacoes table {
  margin-bottom: 0;
}

.resumo-figital-container .tabela-antecipacoes td {
  width: 33.33%;
  padding: 6px 2px;
}

.container-cadastro-agente {
    /* padding-top: 50px; */
}
.documento-container {
  background-color: #DBE3F8 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.documento-container.no-pdf {
  background-color: #FFF !important;
}

.documento-container section.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.documento-container .content {
  background-color: inherit;
  width: 100%;
  /* margin-top: 98px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.documento-container section.form .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.documento-container .checkbox-concordo {
  width: 28px;
  height: 28px;
}

.documento-container label {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.button-doc {
  max-width: 350px;
}

.checkbox-label {
  font-size: 8px;
  text-align: left;
}

.documento-selector {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documento-selector label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  margin-right: 5px;
}

.documento-selector .selector-label {
  float: left;
}

.documento-selector .selector-items {
  float: right;
}

.documento-selector .span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #eeeeee;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;

  text-align: center;
  position: static;
  width: 34px;
  height: 36px;
  left: calc(50% - 34px / 2);
  top: calc(50% - 18px / 2);
  font-size: 20px;
  font-weight: 500;
  margin-top: 2px;
}

.documento-selector .visualizado {
  background-color: #69af1a;
  color: #fff;
}

.documento-selector .visualizado .check {
  max-width: 80%;
  height: auto;
}

.documento-selector .vigente {
  background-color: #ffeade;
  color: #e15100;
}

.documento-selector .pendente {
  color: #757575;
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .documento-selector {
    margin-top: 15px;
  }

  .documento-selector .span {
    font-size: 16px;
    width: 30px;
    height: 32px;
    left: calc(50% - 30px / 2);
    top: calc(50% - 15px / 2);
  }
}

@media screen and (max-width: 414px) {
  .documento-selector {
    margin-top: 30px;
  }
}

.color-resolve {
  background-color: #e15100;
}

.assinatura-container svg {
  margin-bottom: 20px;
}

.assinatura-container p {
  font-size: 24px;
  color: #fff;
}

.token-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.token-container .titulo-token {
  margin-bottom: 20px;
}

.token-container h5 {
  margin-bottom: 15px;
}

.token-container section.form {
  width: 100%;
  max-width: 450px;
}

.token-container section.form h1 {
  font-size: 23px;
}

.token-container section.form h2 {
  margin-bottom: 15px;
}

.token-container section.form input {
  border: 1px solid rgb(139, 139, 139);
  height: 50px;
  margin-bottom: 15px;
  font-size: 15px;
}

.token-container section.form p {
  font-weight: 600;
  font-size: 14px;
}

.token-container section.form a {
  cursor: pointer;
  color: #0B45E4;
}

.token-container section.form img {
  margin: 0 auto;
  width: 60px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.passos-container {
  position: relative;
}

.passos-container .conteudo h1 {
  margin-bottom: 25px;
}

.passos-container .conteudo h2 {
  margin-bottom: 35px;
}

.passos-container .conteudo p.info-adicional {
  /* font-size: 16px;
  color: #757575;
  position: absolute;
  bottom: 0;
  margin-bottom: 35px; */
  font-size: 16px;
    color: #757575;
    position: unset;
    bottom: 0;
    margin-bottom: unset;
    margin-top: 35px;
}

.passos-container .conteudo p.info-adicional a {
  text-decoration: underline;
  color: #212121;
}

.passos-container .conteudo .instrucoes-container {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}

.passos-container .conteudo .instrucoes-container .instrucao {
  display: flex;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 20px;
  width: 2px;
  height: calc(100% - 15px);
  background-color: var(--dynamic-line-color, #2b3ea1);
}

.passos-container .conteudo .instrucoes-container .instrucao .instrucao-icone {
  border: 1px solid #2b3ea1;
  border-radius: 10px;
  padding: 7px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao
  .titulo {
  font-weight: 700;
  font-size: 16px;
}

.passos-container
  .conteudo
  .instrucoes-container
  .instrucao
  .instrucao-descricao
  .subtitulo {
  color: #616161;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .passos-container {
    padding-bottom: 100px !important;
  }

  /* .passos-container .conteudo p.info-adicional {
    font-size: 16px;
    color: #757575;
    position: unset;
    bottom: 0;
    margin-bottom: unset;
    margin-top: 35px;
  } */
}

.confirmacao-dados-container .acoes a {
  margin-right: 30px;
  color: #2b3ea1;
  font-size: 16px;
  cursor: pointer;
}

.confirmacao-dados-container form {
  width: 100%;
}

.confirmacao-dados-container form .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.confirmacao-dados-container form .checkbox-group .checkbox-concordo {
  width: 15px;
  height: 15px;
}

.confirmacao-dados-container form .checkbox-group .checkbox-label {
  font-size: 10px;
}

.acoes-modal-confirmacao {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}

.acoes-modal-confirmacao a {
  margin-right: 25px;
  color: #2b3ea1;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 455px) {
  .acoes-modal-confirmacao {
    margin-top: 15px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  .acoes-modal-confirmacao .button-bem-v2 {
    margin-top: 10px;
  }
}

.conclusao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conclusao-container h1 {
  margin-bottom: 20px;
}

.conclusao-container h2 {
  margin-bottom: 10px;
}

.conclusao-container p {
  margin-bottom: 8px;
}

.conclusao-container p {
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conclusao-container a.download {
  text-decoration: underline;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.conclusao-container p > a {
  text-decoration: underline;
  font-size: 14px;
}

.conclusao-container ul li {
  margin-bottom: 5px;
  margin-left: 20px;
}

.conclusao-container ul {
  padding-right: 35px;
  font-size: 16px;
}

@media screen and (max-width: 414px) {
  .conclusao-container {
    padding-top: 50px;
  }
}

.feedback-container {
  /* box-shadow: 0px 5px 15px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid #eeeeee;
}

.feedback-container.feedback-concluido {
  border: none;
}

.feedback-container .feedback-titulo {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  background-color: #fafafa;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #212121;

  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-container.feedback-concluido .feedback-titulo {
  background-color: #fff;
  border-bottom: none;
}

.feedback-container .feedback-conteudo {
  width: 100%;
  padding-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback-container.feedback-concluido .feedback-conteudo {
  border-radius: 10px;
  border: 1.13px solid #DEE0E1;
  /* background-color: #DBE3F8; */
}

.feedback-container h2 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #212121;
}

.feedback-container .feedback-lista-opcoes {
  padding: 1em 0;
  width: 30%;

  display: flex;
  justify-content: space-evenly;
}

.feedback-container .feedback-lista-opcoes img {
  width: 100%;
}

.feedback-container .feedback-lista-opcoes input {
  display: none;
}

.feedback-container .feedback-lista-opcoes label {
  margin: 2px;
  opacity: 0.7;
  /* filter: grayscale(1); */
  cursor: pointer;
}

.feedback-container .feedback-lista-opcoes label:hover {
  filter: grayscale(0.84);
  transform: scale(1.1);
}

.feedback-container .feedback-lista-opcoes .estrela.ativa {
  filter: grayscale(0);
  opacity: 1;
  transform: scale(1.1);
}

.feedback-container .feedback-descricao {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-container .feedback-descricao form {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback-container .feedback-descricao textarea {
  width: 100%;
  height: 85px;
  display: block;
  padding: 0.5em;
  border: 1px solid #d2d3d8;
  border-radius: 3px;
  resize: none;
  font-size: 14px;
  background-color: #fafafa;
}

.feedback-container .feedback-descricao button {
  height: 48px;
  line-height: unset;
  margin-bottom: 15px;
}

.feedback-container .estrela {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(/static/media/estrela.be827d8e.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.feedback-container .estrela.ativa {
  background-image: url(/static/media/estrela-ativa.ccf6ed8d.svg);
}

@media screen and (max-width: 800px) {
  .feedback-container .feedback-lista-opcoes {
    width: 60%;
  }
}
.download-container {
  width: 40%;
  min-width: 300px;
  margin-top: 25px;
}

.download-container input {
  height: 48px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  width: 100%;
  padding-left: 8px;
  font-size: 14px;
  margin-top: 16px;
}

.politica-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.politica-container section.info-lgpd {
    width: 100%;
    max-width: 450px;
    color: rgb(20,50,75);
}

.politica-container section.info-lgpd div.info-cabecalho {
    margin-bottom: 35px;
}

.politica-container section.info-lgpd div.info-cabecalho h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.politica-container section.info-lgpd div.info-cabecalho p {
    font-weight: 500;
    margin-bottom: 50px;
}

.politica-container section.info-lgpd div.info-texto {
    width: 100%;
    margin-bottom: 20px;
}

.politica-container section.info-lgpd div.info-texto h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.politica-container section.form {
    width: 100%;
    max-width: 350px;
}

.politica-container section.form .checkbox-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.politica-container .checkbox-concordo {
    width: 20px;
    height: 20px;
}

.politica-container label {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
}

.verificacao-container fieldset {
  width: 100%;
  margin-bottom: 0;
}

.verificacao-container h5 {
  margin-bottom: 25px;
}

@media screen and (max-width: 800px) {
  .verificacao-container h5 {
    font-size: 16px !important;
  }
}

.localizacao-container h4,
.localizacao-container h5 {
  margin-top: 25px;
}

@media screen and (max-width: 800px) {
  .localizacao-container img {
    width: 100%;
  }
}

.localizacao-aviso-container {
  background-color: #e15100;
  color: white;

  flex-direction: column;
}

.localizacao-aviso-container img {
    margin-bottom: 25px;
}

.localizacao-aviso-container button {
    max-width: 450px;
}

.localizacao-aviso-container button.finalizar {
    border: 1px solid white;
    background-color: #e15100;
}

.sessao-container {
  background-color: #e8f1ff;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 16px;
}

.sessao-container .content-sessao-expirada {
  background-color: #fff;
  padding: 80px 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  @media screen and (max-width: 800px) {
    padding: 60px 40px;
  }
}

.sessao-container .content-sessao-expirada h1 {
  color: #212121;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 15px;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
}

.sessao-container .content-sessao-expirada h3 {
  color: #616161;
  font-weight: 400;
  font-size: 24px;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
}

.aviso-container {
  background: #fff;

  flex-direction: column;
}

.aviso-container svg {
  margin-bottom: 25px;
}

.aviso-container h3 {
  margin-bottom: 15px;
}

.aviso-container .lista-alertas {
  margin-top: 15px;
}

.aviso-container .lista-alertas li {
  color: #616161;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 20px !important;
  }
}

.qrcode-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 20px;
}

.qrcode-container H2 {
   margin-bottom: 10px;
}
.navegador-container {
  padding-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navegador-container h2 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 18px;
}

.navegador-container button {
  max-width: 450px;
}

.navegador-container button.copiar-link {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.container-imagem-anexada {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagem-anexada {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.button-anexo-bem {
  width: 100%;
  background-color: #fafafa;
  border: 1px dashed #dee0e1;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.button-anexo-bem p.titulo {
  font-size: 16px;
  color: #212121;
  margin-bottom: 5px;
}

.button-anexo-bem p.subtitulo {
  font-size: 12px;
  color: #616161;
}

@media screen and (max-width: 414px) {
  /* .anexo-container {
    padding-top: 50px;
  } */
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  /* .anexo-container {
    padding-top: 50px;
  } */
}

.anexo-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 150px;
  width: 100%;
}

.anexo-container h4 {
  margin-bottom: 25px;
}

.anexo-container button.button-bem-v2 {
  max-width: 450px;
}

.anexo-container .acoes-anexo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.anexo-container button.button-branco {
  border: 1px solid #2b3ea1;
  background-color: white;
  color: #2b3ea1;
}

.anexo-container ul li {
  color: #2b3ea1;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .anexo-container {
    padding-top: 50px;
  }

  .anexo-container button.button-bem {
    font-size: 15px;
  }
}

.autenticacao-portal-container section.form {
  width: 100%;
  max-width: 450px;
}

.autenticacao-portal-container section.form fieldset.fs-senha {
  margin-bottom: 0;
}

.autenticacao-portal-container section.form a.redefine-senha {
  color: #00f;
  float: right;
  cursor: pointer;
  margin-bottom: 20px;
}

.detalhes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.detalhes-container .lista-detalhes {
  width: 100%;
  max-width: 450px;
  --border: 1px solid #ccc;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.detalhes-container .card-detalhe {
  border-bottom: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  height: 100px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.detalhes-container .card-detalhe .card-info {
  margin-right: 20px;
}

.detalhes-container .card-detalhe .card-info span {
  color: #333;
  display: block;
}

.detalhes-container .card-detalhe .card-info h2 {
  display: inline;
}

.detalhes-container .card-detalhe .card-acao {
  width: 40%;
  margin-left: 30px;
}

.detalhes-container .card-detalhe .card-acao .button-bem {
  margin-top: 0;
  margin-right: 10px;
}

.detalhes-container .card-detalhe .card-acao button.download {
  max-width: 450px;
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.detalhes-container .card-detalhe .card-acao span.prazo-expirado {
  color: red;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 414px) {
  .detalhes-container {
    padding-top: 50px;
  }

  .detalhes-container .card-detalhe .card-info h2 {
    font-size: 11px;
  }

  .detalhes-container .card-detalhe .card-info span {
    font-weight: 700;
    font-size: xx-small;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .detalhes-container {
    padding-top: 50px;
  }

  .detalhes-container .card-detalhe {
    padding-left: 5px;
    padding-right: 5px;
  }

  .detalhes-container .card-detalhe .card-info h2 {
    font-size: 9px;
  }

  .detalhes-container .card-detalhe .card-info span {
    font-weight: 700;
    font-size: xx-small;
  }
}

.token-portal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.token-portal-container section.form {
  width: 100%;
  max-width: 450px;
}

.token-portal-container section.form h1 {
  font-size: 23px;
}

.token-portal-container section.form h2 {
  margin-bottom: 15px;
}

.token-portal-container section.form input {
  border: 1px solid rgb(139, 139, 139);
  height: 50px;
  margin-bottom: 4px;
  font-size: 15px;
}

.token-portal-container section.form p {
  font-weight: 600;
  font-size: 14px;
}

.token-portal-container section.form a {
  cursor: pointer;
}

.token-portal-container section.form img {
  margin: 0 auto;
  width: 60px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.redefinir-senha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.redefinir-senha-container form {
  width: 100%;
  max-width: 450px;
}

.orientacao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.orientacao-container button {
  max-width: 450px;
  margin-top: 40px;
}

@media screen and (max-width: 414px) {
  .orientacao-container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .orientacao-container {
    padding-top: 50px;
  }
}

.camera-container h4,
.camera-container h5 {
  margin-top: 25px;
}

.solicitacao-acesso-container {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  padding-top: 60px;
}

.solicitacao-acesso-container p {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}

.solicitacao-acesso-container p b {
  color: #2B3EA1;
}

.solicitacao-acesso-container button {
  max-width: 450px;
}

.solicitacao-acesso-container .container-gifs {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 40px;
}

.solicitacao-acesso-container .container-gifs svg:first-child{
  margin-right: 20px;
}

.usuario-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.usuario-container section.form {
    width: 100%;
    max-width: 450px;
}

.usuario-container section.form h2 {
  color: rgb(20, 50, 75);
}

.usuario-container form button {
  max-width: 450px;
}

.camera-instrucao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.camera-instrucao-container button {
  margin-top: 20px;
  max-width: 450px;
}

.camera-instrucao-container .instrucao-camera {
  font-size: 20px;
}

.camera-instrucao-container .instrucao-camera h1 {
  margin-bottom: 10px;
}

.camera-instrucao-container .instrucao-camera ol {
  margin-left: 2em;
}

@media screen and (max-width: 414px) {
  .camera-instrucao-container {
    padding-top: 50px;
  }
  .camera-instrucao-container .instrucao-camera {
    font-size: 1.5em;
  }
  .camera-instrucao-container .instrucao-camera ol {
    margin-left: 2em;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .camera-instrucao-container {
    padding-top: 50px;
  }
  .camera-instrucao-container .instrucao-camera {
    font-size: 1em;
  }
  .camera-instrucao-container .instrucao-camera ol {
    margin-left: 2em;
  }
}

.informacoes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.informacoes-container button {
  max-width: 450px;
}

.informacoes-container .checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.informacoes-container .checkbox-concordo {
  width: 28px;
  height: 28px;
}

.informacoes-container label {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.informacoes-container table thead tr th {
  border-radius: unset;
}

.informacoes-container table thead tr th:first-child {
  border-top-left-radius: 10px;
}

.informacoes-container table thead tr th:last-child {
  border-top-right-radius: 10px;
}

.informacoes-container table tbody tr td {
  width: auto;
}

@media screen and (max-width: 414px) {
  .informacoes-container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .informacoes-container {
    padding-top: 50px;
  }
}


.responsive-table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
}

.responsive-table thead {
    visibility: hidden;
    display: none;
}

.responsive-table tr {
    padding: 5px;
    margin-bottom: 10px;
    display: block;
}


.responsive-table th,
.responsive-table td {
    padding: 10px;
    text-align: left;
}

.responsive-table th {
    font-size: 11px;
}

.responsive-table td {
    display: block;
    text-align: right;
    font-size: 13px;
}

.responsive-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    width: 50%;
    text-align: initial;
}

@media screen and (min-width: 600px) {
    .responsive-table thead {
        visibility: visible;
        display: contents;
    }

    .responsive-table tr {
        display: table-row;
        border-bottom-width: 1px;
        margin-bottom: 0;
    }

    .responsive-table td {
        display: table-cell;
        text-align: left;
        font-size: 14px;
        border-bottom: none;
    }

    .responsive-table td:before {
        content: "";
        display: none;
    }
}

@media screen and (max-width: 599px) {
    .responsive-table {
        border: 0;
    }

    .responsive-table.table-group tbody tr td {
        border: 0;
    }

    .responsive-table tr {
        border: solid 1px #2B3EA1;
        border-radius: 6px;
    }

    .responsive-table td:before  {
        color: #2B3EA1;
    }

    .responsive-table.table-group tbody tr td {
        padding: 8px 6px !important;
        border-bottom: 1px dashed #c5c5c5;
    }
}

.subrogacao p {
  line-height: 22px;
  padding: 0 10px 15px;
  text-align: justify;
}

.subrogacao p.n2 {
  padding: 0 10px 10px 25px;
  margin-top: -15px;
}

.subrogacao table thead th {
  font-size: 13px;
  font-weight: bold;
}

.subrogacao table tbody td {
  font-size: 12px;
}

.subrogacao .table-container {
  width: 100%;
  margin-bottom: 15px;
}

.subrogacao .table-container p {
  padding-bottom: 0;
}

@media screen and (max-width: 800px) {
  body {
      font-size: 13px;
  }

  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 50px;
      border-bottom: 0;
  }
}

@media screen and (max-width: 431px) {
  .table-group tbody tr td,
  .table-group-v2 tbody tr td {
      font-size: 11px;
  }
}

@media screen and (max-width: 411px) {
  .table-group tbody tr td:nth-child(1),
  .table-group-v2 tbody tr td:nth-child(1)  {
      min-height: 46px;
  }
}

@media screen and (max-width: 399px) {
  .table-group tbody tr td:nth-child(3),
  .table-group-v2 tbody tr td:nth-child(3) {
      min-height: 46px;
  }
}

@media screen and (max-width: 335px) {
  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 68px;
  }
}

@media screen and (max-width: 276px) {
  .table-group tbody tr td:last-child,
  .table-group-v2 tbody tr td:last-child {
      min-height: 95px;
  }
}
.informacoes-frase {
  display: block
}
.identificacao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.identificacao-container .titulo {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.identificacao-container .camera {
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.identificacao-container .camera.loader-camera {
  margin: 85px;
  width: auto;
}

.identificacao-container .camera img,
.identificacao-container .camera video {
  border-radius: 5px;
  max-width: 450px;
}

.identificacao-container .camera img {
  border: 1px solid #5889fa;
}

.identificacao-container .acoes {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.identificacao-container .acoes button {
  padding: 0;
  line-height: unset;

  height: 40px;
  width: 250px;
}

.identificacao-container .frente-verso {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.identificacao-container .frente-verso img {
  width: 200px;
}

@media screen and (max-width: 800px) {
  .identificacao-container {
    padding: 0 16px;
    padding-top: 65px;
  }

  .identificacao-container > div {
    width: 100%;
  }

  .identificacao-container .frente-verso {
    flex-direction: column;
  }

  .identificacao-container .acoes button {
    font-size: 15px;
  }

  .identificacao-container .camera.loader-camera {
    margin: 25px;
  }

  .identificacao-container .camera {
    /* width: 100%; */
    height: 220px;
    object-fit: cover;
  }

  .identificacao-container .camera img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .identificacao-container .camera {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .identificacao-container .camera {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .identificacao-container .camera img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
}

.video-usuario-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video-usuario-container,
.score-biometrico-container {
  width: 100%;
}

.video-usuario-container h1 {
  color: rgb(20, 50, 75);
}

.video-usuario-container video {
  width: 100%;
  max-width: 450px;
}

.video-usuario-container button {
  max-width: 450px;
}

.spinner-loader {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.score-biometrico-container .table-small td {
  font-size: 12px;
  padding: 2px 6px;
}

.score-biometrico-container .table-group tbody tr td {
  width: 0;
}

.score-biometrico-container .table-group tbody tr td,
.score-biometrico-container .table-group thead tr th {
  text-align: center;
}

.score-biometrico-container .table-group tbody tr .td-image{
  border: solid 1px #e15100;
  border-bottom: 0;
  border-right: 0;
  width: 22%;
}

.width33 {
  width: 33.33% !important;
}
.validador-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  min-height: 100vh;
}

.validador-container .bemsign {
  width: 100%;
  max-width: 28%;
  height: auto;
  background: linear-gradient(73.16deg, #ffbe2d 11.41%, #ff6518 74.48%);
  padding: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.validador-container .bemsign .info {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
}

.validador-container .bemsign .info img#logo-bemsign {
  width: 200px;
}

.validador-container .bemsign .info p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #fff;
  margin-top: 5px;
}

.validador-container .conteudo {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 15px 0px 15px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.validador-container .conteudo header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.validador-container .conteudo header svg {
  position: absolute;
  left: 0;
}

.validador-container h1 {
  color: #414141;
  display: flex;
  align-items: center;
  justify-content: center;
}

.validador-container h1.titulo b {
  color: #e15100;
  margin-left: 5px;
}

.validador-container .dados-assinatura {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.validador-container .dados-assinatura .container-dados {
  padding: 10px;
}

.validador-container .dados-assinatura .container-dados.foto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dados-assinatura .container-dados .foto-cliente {
  height: 250px;
  border: 2px solid #ccc;
}

.validador-container .dados-assinatura div {
  width: 100%;
}

.validador-container .dados-assinatura div label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: #a7a7a7;
}

.validador-container .dados-assinatura div p {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  padding: 5px 0;
  margin-bottom: 10px;
  color: #545454;
  word-wrap: break-word;
}

.validador-container .dados-assinatura div p.validado {
  color: green;
}

.validador-container input.identificador-documento {
  width: 80%;
  font-size: 1em;
  font-weight: 700;
  padding: 20px 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #333;
}

.validador-container button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.validador-container button.secundario {
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.validador-container button,
.validador-container .video-qrcode div video {
  max-width: 450px;
}

.validador-container button {
  font-size: 0.89em;
}

.validador-container button svg {
  margin-right: 10px;
}

.validador-container button.button-fundo-vazado,
.validador-container button.button-fundo-escuro {
  height: auto;
  line-height: 50px;
  margin-top: 10px;
}

.validador-container button.button-fundo-escuro {
  background-color: #e15100;
}

.validador-container button.button-fundo-vazado {
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  color: #e15100;
}

.validador-container .video-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.validador-container .video-qrcode div {
  padding: 0 !important;
}

.validador-container .video-qrcode div video {
  border: 5px solid #2b3ea1;
  border-radius: 8px;
  position: inherit !important;
}

.validador-container .dica-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .instrucao {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .instrucao p {
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 20px;
  color: #787875;
}

.validador-container .dica-qrcode .instrucao p:last-child {
  margin-bottom: 30px;
}

.validador-container .dica-qrcode .exemplo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validador-container .dica-qrcode .exemplo img {
  min-width: 239px;
  margin-bottom: 10px;
}

.validador-container .container-verificacao-assinatura {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 15px;
  margin-bottom: 100px;
  grid-gap: 20px;
  gap: 20px;
}

.validador-container .dados-assinatura img {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1065px) {
  .validador-container {
    justify-content: center;
    height: auto;
  }

  .validador-container .bemsign {
    display: none;
  }

  .validador-container .botoes-download button {
    width: 350px;
  }
}

@media screen and (max-width: 950px) {
  .validador-container .container-verificacao-assinatura {
    padding: 10px 10px;
  }

  .validador-container .dados-assinatura {
    flex-direction: column;
    padding: 10px;
  }

  .validador-container .dados-assinatura div:first-child {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 750px) {
  .validador-container .dica-qrcode .instrucao p {
    font-size: 15px;
  }

  .validador-container h1 {
    font-size: 20px;
  }

  .validador-container .botoes-download button {
    width: 230px;
  }

  .validador-container .dados-assinatura div p {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .validador-container button.button-resize,
  .validador-container h1.titulo {
    width: 95%;
  }

  .validador-container .botoes-download button {
    width: 220px;
  }

  .validador-container .botoes-download {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.tentativa-container {
    background: linear-gradient(162.43deg, #e15100 49.02%, #ff9e25 107.26%);
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tentativa-container img {
    margin-bottom: 20px;
}
.login-container {
    padding: 0 20px;
    padding-top: 50px;


    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.login-container form {
    width: 100%;
    max-width: 450px;
}

.login-container img {
    margin-bottom: 30px;
}

.login-container form button {
    background-color: #010050;
}
.score-container {
    padding: 0 20px;
    padding-top: 50px;
    margin-bottom: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.score-container .inclusao-biometria {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.score-container .inclusao-biometria button.button-bem {
    margin-top: 0;
}

.score-container .logo-titulo {
    width: 100%;
    margin: 15px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score-container .logo-titulo h1 {
    font-size: 1.3rem;
    margin: 0;
}

.score-container .logo-titulo img {
    width: 35px;
    margin-right: 10px;
}

.score-container button.button-bem,
.modal-biometria-score button.button-bem {
    background-color: #010050
}

.modal-biometria-score {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-biometria-score .full-width-label {
    width: 100%;
}

.score-container .busca {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.score-container .busca form {
    display: flex;
    align-items: center;
}

.score-container .busca fieldset {
    margin-right: 10px;
    margin-bottom: 5px;
}

.score-container .busca form input {
    height: 35px;
}

.score-container .busca .campos {
    display: flex;
}

.score-container .busca button {
    height: auto;
    width: 150px;
    line-height: 35px;
    margin-top: 5px;
}

.score-container button {
    max-width: 450px;
}

.score-container table span {
    display: block;
}

.score-container th {
    background-color: #737381;
    color: white;
    padding: 8px;
}

.score-container th:first-child {
    border-top-left-radius: 8px;
}

.score-container th:last-child {
    border-top-right-radius: 8px;
}

.score-container td {
    background-color: white;
    border: 1px solid #adaeb3;
    padding: 8px;
}

.score-container table {
    border-collapse: collapse;
    width: 100%;
}

.score-container table a {
    text-decoration: underline;
    cursor: pointer;
    color: #3366cc;
}

.score-container tr:nth-child(odd) {
    background-color: #E0E0E0;
}

@media screen and (max-width: 800px) {
    .score-container .busca form {
        flex-direction: column;
    }

    .score-container .busca form .campos {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .score-container .busca form .campos fieldset {
        margin-right: 0;
    }

    .score-container .busca form .botao {
        width: 100%;

        display: flex;
    }
}
.biometria-score-container {
    padding: 0 20px;
    padding-top: 50px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.biometria-score-container button.button-bem {
    background-color: #010050;
    max-width: 450px;
}

.biometria-score-container button.finalizar {
    border: 1px solid #010050;
    background-color: #fff;
    color: #010050;
}

.biometria-score-container .informacoes {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.biometria-score-container form {
    width: 100%;
    max-width: 450px;
}

.biometria-score-container .camera {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.biometria-score-container .camera video {
    clip-path: ellipse(90px 140px at 50% 50%);

    width: 100%;
    height: 100%;
}
.score-exibicao-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.score-exibicao-container table {
    border-left: solid 1px #010050;
    border-right: solid 1px #010050;
    border-bottom: solid 1px #010050;
    border-radius: 10px;
}

.score-exibicao-container table th {
    background-color: #010050 !important;
}

.score-exibicao-container tbody tr td {
    border-top: solid 1px #010050;
}

.score-exibicao-container img {
    width: 100%;
}
.relatorio-container {
    padding: 0 20px;
    padding-top: 50px;
    margin-bottom: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.relatorio-container button.button-bem {
    background-color: #010050
}

.relatorio-container .busca {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.relatorio-container .busca form {
    display: flex;
    align-items: center;
}

.relatorio-container .busca fieldset {
    margin-right: 10px;
    margin-bottom: 5px;
}

.relatorio-container .busca form input {
    height: 35px;
}

.relatorio-container .busca .campos {
    display: flex;
}

.relatorio-container .busca button {
    height: auto;
    width: 150px;
    line-height: 35px;
    margin-top: 5px;
}

.relatorio-container button {
    max-width: 450px;
}

@media screen and (max-width: 800px) {
    .relatorio-container .busca form {
        flex-direction: column;
    }

    .relatorio-container .busca form .campos {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .relatorio-container .busca form .campos fieldset {
        margin-right: 0;
    }

    .relatorio-container .busca form .botao {
        width: 100%;

        display: flex;
    }
}
