.autorizacao-liquidar-divida-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 414px) {
  .autorizacao-liquidar-divida-container {
    /* padding-top: 50px; */
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .autorizacao-liquidar-divida-container {
    /* padding-top: 50px; */
  }
}
