.container-ccb {
  /* padding-top: 50px; */
}

.container-ccb .p-cabecalho {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: left;
}
.container-ccb .p-condicoes {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: justify;
}
.container-ccb .div-condicoes {
  padding: 0px;
}
.container-ccb .div-condicoes > p {
  font-size: 9px !important;
  padding: 0px !important;
  text-align: justify;
}

#valorasercreditado .titulo {
  font-weight: 700;
}

#valorasercreditado tbody tr td {
  width: 33%;
}
