.conclusao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conclusao-container h1 {
  margin-bottom: 20px;
}

.conclusao-container h2 {
  margin-bottom: 10px;
}

.conclusao-container p {
  margin-bottom: 8px;
}

.conclusao-container p {
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conclusao-container a.download {
  text-decoration: underline;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.conclusao-container p > a {
  text-decoration: underline;
  font-size: 14px;
}

.conclusao-container ul li {
  margin-bottom: 5px;
  margin-left: 20px;
}

.conclusao-container ul {
  padding-right: 35px;
  font-size: 16px;
}

@media screen and (max-width: 414px) {
  .conclusao-container {
    padding-top: 50px;
  }
}
