.timer-container {
  background-color: rgb(20, 50, 75);
  margin-top: 30px;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 15px;
}

.timer-container span {
  color: #fff;
  font-size: 30px;
  display: flex;
  min-width: 40px;
  justify-content: center;
}
