.feedback-container {
  /* box-shadow: 0px 5px 15px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid #eeeeee;
}

.feedback-container.feedback-concluido {
  border: none;
}

.feedback-container .feedback-titulo {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  background-color: #fafafa;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #212121;

  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-container.feedback-concluido .feedback-titulo {
  background-color: #fff;
  border-bottom: none;
}

.feedback-container .feedback-conteudo {
  width: 100%;
  padding-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback-container.feedback-concluido .feedback-conteudo {
  border-radius: 10px;
  border: 1.13px solid #DEE0E1;
  /* background-color: #DBE3F8; */
}

.feedback-container h2 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #212121;
}

.feedback-container .feedback-lista-opcoes {
  padding: 1em 0;
  width: 30%;

  display: flex;
  justify-content: space-evenly;
}

.feedback-container .feedback-lista-opcoes img {
  width: 100%;
}

.feedback-container .feedback-lista-opcoes input {
  display: none;
}

.feedback-container .feedback-lista-opcoes label {
  margin: 2px;
  opacity: 0.7;
  /* filter: grayscale(1); */
  cursor: pointer;
}

.feedback-container .feedback-lista-opcoes label:hover {
  filter: grayscale(0.84);
  transform: scale(1.1);
}

.feedback-container .feedback-lista-opcoes .estrela.ativa {
  filter: grayscale(0);
  opacity: 1;
  transform: scale(1.1);
}

.feedback-container .feedback-descricao {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-container .feedback-descricao form {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback-container .feedback-descricao textarea {
  width: 100%;
  height: 85px;
  display: block;
  padding: 0.5em;
  border: 1px solid #d2d3d8;
  border-radius: 3px;
  resize: none;
  font-size: 14px;
  background-color: #fafafa;
}

.feedback-container .feedback-descricao button {
  height: 48px;
  line-height: unset;
  margin-bottom: 15px;
}

.feedback-container .estrela {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../assets/v2/estrela.svg");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.feedback-container .estrela.ativa {
  background-image: url("../../assets/v2/estrela-ativa.svg");
}

@media screen and (max-width: 800px) {
  .feedback-container .feedback-lista-opcoes {
    width: 60%;
  }
}