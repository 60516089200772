.score-container {
    padding: 0 20px;
    padding-top: 50px;
    margin-bottom: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.score-container .inclusao-biometria {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.score-container .inclusao-biometria button.button-bem {
    margin-top: 0;
}

.score-container .logo-titulo {
    width: 100%;
    margin: 15px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score-container .logo-titulo h1 {
    font-size: 1.3rem;
    margin: 0;
}

.score-container .logo-titulo img {
    width: 35px;
    margin-right: 10px;
}

.score-container button.button-bem,
.modal-biometria-score button.button-bem {
    background-color: #010050
}

.modal-biometria-score {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-biometria-score .full-width-label {
    width: 100%;
}

.score-container .busca {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
}

.score-container .busca form {
    display: flex;
    align-items: center;
}

.score-container .busca fieldset {
    margin-right: 10px;
    margin-bottom: 5px;
}

.score-container .busca form input {
    height: 35px;
}

.score-container .busca .campos {
    display: flex;
}

.score-container .busca button {
    height: auto;
    width: 150px;
    line-height: 35px;
    margin-top: 5px;
}

.score-container button {
    max-width: 450px;
}

.score-container table span {
    display: block;
}

.score-container th {
    background-color: #737381;
    color: white;
    padding: 8px;
}

.score-container th:first-child {
    border-top-left-radius: 8px;
}

.score-container th:last-child {
    border-top-right-radius: 8px;
}

.score-container td {
    background-color: white;
    border: 1px solid #adaeb3;
    padding: 8px;
}

.score-container table {
    border-collapse: collapse;
    width: 100%;
}

.score-container table a {
    text-decoration: underline;
    cursor: pointer;
    color: #3366cc;
}

.score-container tr:nth-child(odd) {
    background-color: #E0E0E0;
}

@media screen and (max-width: 800px) {
    .score-container .busca form {
        flex-direction: column;
    }

    .score-container .busca form .campos {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .score-container .busca form .campos fieldset {
        margin-right: 0;
    }

    .score-container .busca form .botao {
        width: 100%;

        display: flex;
    }
}