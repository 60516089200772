.botao-fixado {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.botao-fixado button {
  max-width: 450px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.botao-fixado button img {
  margin-left: 10px;
}

.botao-fixado.doc-wide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .button-bem-v2 {
    margin: 0;
    width: 150px;
  }
}

.botao-fixado.rm-mg-button {
  .button-bem-v2 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .botao-fixado {
    width: 100%;
  }
}
