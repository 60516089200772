.download-container {
  width: 40%;
  min-width: 300px;
  margin-top: 25px;
}

.download-container input {
  height: 48px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  width: 100%;
  padding-left: 8px;
  font-size: 14px;
  margin-top: 16px;
}
