.termo-autorizacao-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.termo-autorizacao-container p {
  font-size: 10px;
}

@media screen and (max-width: 414px) {
  .termo-autorizacao-container {
    /* padding-top: 50px; */
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .termo-autorizacao-container-container {
    /* padding-top: 50px; */
  }
}
