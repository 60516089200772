.detalhes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.detalhes-container .lista-detalhes {
  width: 100%;
  max-width: 450px;
  --border: 1px solid #ccc;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.detalhes-container .card-detalhe {
  border-bottom: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  height: 100px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.detalhes-container .card-detalhe .card-info {
  margin-right: 20px;
}

.detalhes-container .card-detalhe .card-info span {
  color: #333;
  display: block;
}

.detalhes-container .card-detalhe .card-info h2 {
  display: inline;
}

.detalhes-container .card-detalhe .card-acao {
  width: 40%;
  margin-left: 30px;
}

.detalhes-container .card-detalhe .card-acao .button-bem {
  margin-top: 0;
  margin-right: 10px;
}

.detalhes-container .card-detalhe .card-acao button.download {
  max-width: 450px;
  border: 1px solid #2b3ea1;
  background-color: #fff;
  color: #2b3ea1;
}

.detalhes-container .card-detalhe .card-acao span.prazo-expirado {
  color: red;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 414px) {
  .detalhes-container {
    padding-top: 50px;
  }

  .detalhes-container .card-detalhe .card-info h2 {
    font-size: 11px;
  }

  .detalhes-container .card-detalhe .card-info span {
    font-weight: 700;
    font-size: xx-small;
  }
}

@media screen and (max-width: 239px) and (max-height: 460px) {
  .detalhes-container {
    padding-top: 50px;
  }

  .detalhes-container .card-detalhe {
    padding-left: 5px;
    padding-right: 5px;
  }

  .detalhes-container .card-detalhe .card-info h2 {
    font-size: 9px;
  }

  .detalhes-container .card-detalhe .card-info span {
    font-weight: 700;
    font-size: xx-small;
  }
}
