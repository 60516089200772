
.responsive-table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
}

.responsive-table thead {
    visibility: hidden;
    display: none;
}

.responsive-table tr {
    padding: 5px;
    margin-bottom: 10px;
    display: block;
}


.responsive-table th,
.responsive-table td {
    padding: 10px;
    text-align: left;
}

.responsive-table th {
    font-size: 11px;
}

.responsive-table td {
    display: block;
    text-align: right;
    font-size: 13px;
}

.responsive-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    width: 50%;
    text-align: initial;
}

@media screen and (min-width: 600px) {
    .responsive-table thead {
        visibility: visible;
        display: contents;
    }

    .responsive-table tr {
        display: table-row;
        border-bottom-width: 1px;
        margin-bottom: 0;
    }

    .responsive-table td {
        display: table-cell;
        text-align: left;
        font-size: 14px;
        border-bottom: none;
    }

    .responsive-table td:before {
        content: "";
        display: none;
    }
}

@media screen and (max-width: 599px) {
    .responsive-table {
        border: 0;
    }

    .responsive-table.table-group tbody tr td {
        border: 0;
    }

    .responsive-table tr {
        border: solid 1px #2B3EA1;
        border-radius: 6px;
    }

    .responsive-table td:before  {
        color: #2B3EA1;
    }

    .responsive-table.table-group tbody tr td {
        padding: 8px 6px !important;
        border-bottom: 1px dashed #c5c5c5;
    }
}
