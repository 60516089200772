.sessao-container {
  background-color: #e8f1ff;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 16px;
}

.sessao-container .content-sessao-expirada {
  background-color: #fff;
  padding: 80px 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  @media screen and (max-width: 800px) {
    padding: 60px 40px;
  }
}

.sessao-container .content-sessao-expirada h1 {
  color: #212121;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 15px;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
}

.sessao-container .content-sessao-expirada h3 {
  color: #616161;
  font-weight: 400;
  font-size: 24px;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
}
