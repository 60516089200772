.color-resolve {
  background-color: #e15100;
}

.assinatura-container svg {
  margin-bottom: 20px;
}

.assinatura-container p {
  font-size: 24px;
  color: #fff;
}
