.container-contrato-seguro {
  /* padding-top: 50px; */
}

.container-contrato-seguro p {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
}
